<template>
  <div class="searchBox">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="searchTit">电子协议</p>
      <!-- 合作单位 -->
      <div class="yuitem">
        <label class="yulabel" for="">合作单位</label>
        <span class="yuinput">{{ xieyiTit }}</span>
      </div>
      <div class="yuitem">
        <label class="yulabel" for="">电子协议</label>
        <button class="yubtn1" @click="upp">上传</button>
      </div>

      <div class="yuxinBox" ref="newbox">
        <!-- 信息 -->
        <table class="yutable" border="1" cellspacing="0">
          <tr class="yutit">
            <th class="renwu">签署日期</th>
            <th class="mudi">到期日期</th>
            <th class="yikou">上传时间</th>
            <th class="yikou">备注</th>
            <th class="operation">电子协议</th>
          </tr>
          <template v-if="xinxiList.length">
            <tr
              :class="
                (index % 2 == 0 && !changecolor) ||
                (index % 2 !== 0 && changecolor)
                  ? 'bg_color'
                  : 'bg_color2'
              "
              v-for="(item, index) in xinxiList"
              :key="index"
            >
              <td>{{ item.starttime }}</td>
              <td>{{ item.endtime }}</td>
              <td>{{ item.uploadtime }}</td>
              <td>{{ item.remark }}</td>
              <td> <a :href="item.pdf" target="_blank" v-if="pdf != ''" style="font-size:14px;color:blue;">查看</a> <a style="font-size:14px;color:red;cursor: pointer;" @click="xiugai(item.id,item.cid)">修改</a></td>
            </tr>
          </template>
        </table>
      </div>

      <!-- 按钮 -->
      <div class="btn" v-if="isreadonly">
        <button class="set" @click="bao">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>

    <xieyiadd
      :xieyiaddTit="xieyiTit"
      :xieyiaddCid="xieyiCid"
      @closexyy="closexyy"
      v-if="xyAddShow"
    ></xieyiadd>

    <xieyiedit
      :xieyieditTit="xieyiTit"
      :xieyieditCid="xieyiCid"
      :xieyieditId="xieyiId"
      @closexyy2="closexyy2"
      v-if="xyEditShow"
    ></xieyiedit>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import * as api from "../api/index";
import search from "./search.vue";
import Yusearch from "./yusearch.vue";
import xieyiadd from "./xieyiadd.vue";
import xieyiedit from "./xieyiedit.vue";
import { fa } from "element-plus/lib/locale";
export default {
  components: { search, Yusearch,xieyiadd,xieyiedit },
  props: ["isxieyi", "xieyiTit", "xieyiCid"],
  data() {
    return {
      yuweizhi: false,
      yuisaddrs: "",
      foundaddryu1: "",
      foundlnglatyu1: "",
      yushes: this.isyu,
      xinxiList: [],
      price: "",
      price2: "",
      prive3: "",

      hezuo: this.yusheTitP,
      address: "",
      destination: "",
      addressLnglat: "",
      destinationLnglat: "",
      oldaddressLnglat: "",
      olddestinationLnglat: "",
      selectIndex: "",
      isShow: false,
      words: [],
      isShow2: false,
      words2: [],
      ycid: "",
      distance: "",
      id: "",
      flag: "",

      yinbtn: false,
      isreadonly: this.isreadonly,
      xyAddShow:false,
      xyEditShow:false,
      xieyiId:0,
    };
  },

  created() {
    this.getlist();
    console.log("打印判断---", this.xieyiCid);
    console.log("判断选单---", this.xieyiTit);
  },

  methods: {
    xiugai(id,cid)
    {
      console.log(id);
      console.log(cid);
      this.xieyiId = id;
      this.xyEditShow = true;
    },  
    upp()
    {
      this.xyAddShow = true;
    },
    close() {
      this.$emit("closexy");
    },
    closexyy()
    {
      this.getlist();
      this.xyAddShow = false;
    },
    closexyy2()
    {
      this.getlist();
      this.xyEditShow = false;
    },
    hide() {
      this.yuweizhi = false; // 关闭地图组件
    },

    intaddress() {
      console.log("输入框经纬度：", this.addressLnglat, this.destinationLnglat);
    },

    renWei(id) {
      this.yuweizhi = true;
      this.yuisaddrs = id;
      if (this.yuisaddrs == 1) {
        this.foundaddryu1 = this.address;
        this.foundlnglatyu1 = this.addressLnglat;
        console.log("任务点1输入地址1:", this.foundaddryu1);
        console.log("任务点1输入地址2:", this.foundlnglatyu1);
      } else {
        this.foundaddryu1 = this.destination;
        this.foundlnglatyu1 = this.destinationLnglat;
        console.log("目的地2输入地址1:", this.foundaddryu1);
        console.log("目的地2输入地址2:", this.foundlnglatyu1);
      }
    },

    yuaddr(addr1) {
      this.oldaddressLnglat = addr1;
      this.address = addr1;
      this.isShow = false;
    },
    yuaddrlnglat(addr1lnglat) {
      this.addressLnglat = addr1lnglat;
    },
    yudest(addr2) {
      this.olddestinationLnglat = addr2;
      this.destination = addr2;
      this.isShow2 = false;
    },
    yudestlnglat(addr2lnglat) {
      this.destinationLnglat = addr2lnglat;
    },

    // 添加
    async add() {
      console.log("点击添加----");
      let data = {
        cid: this.yushecid,
        address: this.address,
        destination: this.destination,
        address_lnglat: this.addressLnglat,
        destination_lnglat: this.destinationLnglat,
        price: this.price,
        price2: this.price2,
        price3: this.price3,
      };
      let res = await api.added(data);
      console.log("新增预设线路----", res.data);
      this.$message.success("添加成功");
      this.getlist();
    },

    // 修改
    xiu() {
      ElMessageBox.confirm("是否确定修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let data = {
            cid: this.yushecid,
            address: this.address,
            destination: this.destination,
            address_lnglat: this.addressLnglat,
            destination_lnglat: this.destinationLnglat,
            price: this.price,
            price2: this.price2,
            price3: this.price3,
            id: this.id,
          };
          let xiures = await api.xiugai(data);
          console.log("修改数据---", xiures);
          this.$message.success("修改成功");
          this.getlist();
          this.id = "";
          this.price = "";
          this.price2 = "";
          this.price3 = "";
          this.address = "";
          this.destination = "";
          this.addressLnglat = "";
          this.oldaddressLnglat = "";
          this.destinationLnglat = "";
          this.olddestinationLnglat = "";
          this.distance = "";
        })
        .catch(() => {
          this.$message.info("取消修改");
        });
      this.yinbtn = false;
    },



    async getlist() {
      let res = await api.GetXieyi(this.xieyiCid);
      this.xinxiList = res.data;
    },

    // 任务点输入框
    blur() {
      setTimeout(() => {
        this.isShow = false;
        console.log("失去焦点----");
      }, 200);
    },
  },

  watch: {    
    xieyiCid(newval) {
      console.log(111);
      this.ycid = newval;
    },
    xieyiTit(newval) {
      console.log(222);
      console.log("======", newval);
      this.hezuo = newval;
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 640px;
  background-color: skyblue;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
.searchMain {
  width: 640px;
  height: 920px;
  background-color: #fff;
  padding-top: 40px;
  padding-left: 30px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 65px;
  left: -40px;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  font-weight: bold;
  box-sizing: border-box;
}
.yuitem {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.yulabel {
  margin-right: 20px;
  display: inline-block;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  //   line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.yuinput {
  width: 440px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  padding-left: 12px;
  font-weight: 800;
  color:#888888;
}
.yuinput::-webkit-input-placeholder {
  color: #c0c4cc;
}
.mapImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
}
.add {
  float: left;
  width: 30px;
  height: 32px;
  margin-left: 50px;
  cursor: pointer;
}
// .bite {
//   width: 380px;
//   margin-right: 10px;
// }
.yuxinBox {
  width: 580px;
  max-height: 400px;
  margin-top: 24px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
}
.yuxinBox::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
.foundyuxinBox {
  max-height: 800px;
}
.yutable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.yutable td {
  text-align: center;
}
.yubtn {
  width: 50px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 20px;
  border: 0;
  cursor: pointer;
}
.yubtn1 {
  width: 60px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 440px;
  border: 0;
  cursor: pointer;
}
.xiubtn {
  width: 50px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.btn {
  margin-top: 46px;
  // padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.yutit {
  height: 32px;
  background-color: #f5f5f5;
}
// .yutxt {
//   height: 50px;
// }
.gai,
.shan {
  width: 32px;
  height: 20px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
.gai {
  background-color: #2c68ff;
  color: #fff;
}
.shan {
  margin-left: 10px;
}
.renwu,
.mudi {
  width: 120px;
}
.yikou {
  width: 165px;
}
.operation {
  width: 120px;
}
.select-panel {
  // height: 100px;
  position: absolute;
  top: 35px;
  margin-left: 80px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
.input-norm1 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  border-right: 0;
}
.ding {
  position: relative;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  height: 50px;
  background-color: #ffffff !important;
}
.bg_color2 {
  height: 50px;
  background-color: #fafafa !important;
}
.jishi {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 12px;
}
.jishiint {
  width: 80px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
  // text-align: center;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  outline: none;
}
.yuan {
  width: 32px;
  height: 30px;
  box-sizing: border-box;
  background-color: #f7f9fb;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.priceTit,
.juli {
  font-size: 14px;
}
</style>
