<template>
  <div>
    <div class="box" v-show="sphotos" ref="checkbox">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />

      <div class="box-main">
        <div class="main-nav">
          <h1>补传照片</h1>
        </div>

        <div class="state">
          <span class="state-span">订单状态</span>
          <el-tooltip placement="top">
            <template #content>订单详情</template>
            <el-button v-if="detail" @click="typebtn"
              >{{ detail.服务项目 }} | {{ detail.服务状态 }}</el-button
            >
          </el-tooltip>
        </div>

        <div class="state">
          <span class="state-span" for="">车牌车架</span>
          <div class="cartit">{{ detail.车牌号 }}</div>
        </div>

        <el-checkbox-group v-model="checkList" @change="checkChange">
          <div class="owner" v-if="photo1.length > 0">
            <span class="state-span">现场照&#12288;&#12288;</span>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo1" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index"
                  fit="cover"
                >
                </el-image>
                <div class="owBottom">
                  <input
                    class="tu"
                    type="file"
                    @change="change"
                    :data-index="index"
                  />
                  <span class="tutit">{{item.name}} - 替换</span>
                </div>
              </div>
            </div>
          </div>
          <div class="owner" v-if="photo2.length > 0">
            <span class="state-span">作业照&#12288;&#12288;</span>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo2" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index + photo1.length"
                  fit="cover"
                >
                </el-image>
                <div class="owBottom">
                  <input
                    class="tu"
                    type="file"
                    @change="change1"
                    :data-index="index"
                  />
                  <span class="tutit">{{item.name}} - 替换</span>
                </div>
              </div>
            </div>
          </div>

          <div class="owner" v-if="photo3.length > 0">
            <span class="state-span">完成照&#12288;&#12288;</span>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo3" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index + photo1.length + photo2.length"
                  fit="cover"
                >
                </el-image>
                <div class="owBottom">
                  <input
                    class="tu"
                    type="file"
                    @change="change2"
                    :data-index="index"
                  />
                  <span class="tutit">{{item.name}} - 替换</span>
                </div>
              </div>
            </div>
          </div>

          <div class="owner" v-if="photoempty.length > 0">
            <span class="state-span">空驶照&#12288;&#12288;</span>
            <div class="car-photo">
              <div
                class="car-p1"
                v-for="(item, index) in photoempty"
                :key="index"
              >
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="
                    index + photo1.length + photo2.length + photo3.length
                  "
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <div class="owBottom">
                  <input
                    class="tu"
                    type="file"
                    @change="change3"
                    :data-index="index"
                  />
                  <span class="tutit">{{item.name}} - 替换</span>
                </div>
              </div>
            </div>
          </div>
        </el-checkbox-group>

        <div class="btn">
          <el-button class="set" :plain="true" @click="buchuan"
            >确定提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api1 from "../api/orderDetail";
import { ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import sports from "../views/new/sports.vue";
import { compress } from "../api/pic";
import axios from "axios";
const input1 = ref("");
const input2 = ref("");
const input3 = ref("");
const textarea1 = ref("");

export default {
  components: { sports },
  props: ["issphoto", "sphotoid"],
  data() {
    return {
      input1,
      input2,
      input3,
      textarea1,
      sphotos: this.issphoto,
      id: "",
      detail: {},
      photo1: [],
      photo2: [],
      photo3: [],
      photoempty: [],
      photoall: [],
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList: [],
      checkList: [],
      shenshu: "",
      buchuanList: [],
    };
  },
  methods: {
    close() {
      this.sphotos = false;
      this.buchuanList = [];
    },
    checkChange(e) {
      console.log("----", e);
    },
    async buchuan() {
        if(this.buchuanList.length == 0)
        {
            this.$message.warning("没有检测到有补传的照片");
            return false;
        }

        ElMessageBox.confirm("确定是否替换照片", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
        .then(async () => {
          let res = await api1.orderBuchuan(this.id,this.buchuanList);
          if (res.code == 1) {
            // alert('成功');
              this.$message.success("成功");
              this.close();
              this.$emit("partsx", 1);
          } else {
            // alert(res.message);
              this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.warning("取消");
        });
    },
    compressAvatard(file, kb, callback) {
      let _this = this;
      let raw = file.raw ? file.raw : file;
      compress(raw, function (val) {
        // val是Blob类型，转换为file类型
        let newfile = new window.File([val], file.name, { type: raw.type });
        newfile.uid = file.uid;
        // 压缩率过低则停止压缩, 防止死循环
        if (newfile.size / 1024 > kb && newfile.size / file.size < 0.9) {
          console.log("图片过大:" + newfile.size + ",已压缩");
          _this.compressAvatard(newfile, kb, callback);
        } else {
          callback(newfile);
          console.log("压缩后大小:" + newfile.size);
        }
      });
    },
    async change(e) {
      //console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      // console.log(file);
      // console.log(index);
      let type = this.photo1[index].type;
      let id = this.id;
      console.log(type);
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        formData.append("id", id);
        formData.append("type", type);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-rescue-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.photo1[index].now =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.srcList[index] =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.buchuanList[index] = { type: type, pic: res.data.pic };
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },
    async change1(e) {
      //console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      let index2 = index + this.photo1.length;
      let type = this.photo2[index].type;
      let id = this.id;
      // console.log(file);
      // console.log(index);
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        formData.append("id", id);
        formData.append("type", type);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-rescue-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.photo2[index].now =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.srcList[index2] =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.buchuanList[index2] = { type: type, pic: res.data.pic };
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },
    async change2(e) {
      //console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      let index2 = index + this.photo1.length + this.photo2.length;
      let type = this.photo3[index].type;
      let id = this.id;
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        formData.append("id", id);
        formData.append("type", type);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-rescue-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.photo3[index].now =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.srcList[index2] =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.buchuanList[index2] = { type: type, pic: res.data.pic };
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },
    async change3(e) {
      //console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      let index2 = index + this.photo1.length + this.photo2.length + this.photo3.length;
      let type = this.photoempty[index].type;
      let id = this.id;
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        formData.append("id", id);
        formData.append("type", type);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-rescue-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.photoempty[index].now =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.srcList[index2] =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;

              _this.buchuanList[index2] = { type: type, pic: res.data.pic };
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },

    dian() {
      console.log("点击了----");
    },
    async pass() {
      ElMessageBox.confirm("是否确定初审通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.orderView(this.id, 1, "");
          if (res.code == 1) {
            // alert('成功');
            this.$message.success("成功");
            this.close();
            this.$emit("checksx", 1);
            this.$emit("checkedsx", 1);
          } else {
            // alert(res.message);
            this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.warning("取消");
        });
    },
    async fail() {
      let content = "";
      if (this.checkList.length == 0) {
        // alert('请勾选不符合要求的照片');
        this.$message.warning("请勾选不符合要求的照片");
        return;
      } else {
        for (let i = 0; i < this.checkList.length; i++) {
          if (this.checkList[i] != 98 && this.checkList[i] != 99) {
            if (i == 0) {
              content += this.checkList[i];
            } else {
              content += "," + this.checkList[i];
            }
          }
        }
      }

      ElMessageBox.confirm("是否确定初审不通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.orderView(this.id, 2, content);

          if (res.code == 1) {
            // alert('成功');
            this.$message.success("成功");
            this.close();
            this.$emit("checksx", 1);
          } else {
            // alert(res.message);
            this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.warning("取消");
        });
    },

    async passfinal() {
      ElMessageBox.confirm("是否确定终审通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.orderView(this.id, 3, "");
          if (res.code == 1) {
            this.$message.success("成功");
            this.close();
            this.$emit("checksx", 1);
            this.$emit("checkedsx", 1);
          } else {
            this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.warning("取消");
        });
    },
    async failfinal() {
      let content = "";
      if (this.checkList.length == 0) {
        this.$message.warning("请勾选不符合要求的照片");
        return;
      } else {
        for (let i = 0; i < this.checkList.length; i++) {
          if (this.checkList[i] != 98 && this.checkList[i] != 99) {
            if (i == 0) {
              content += this.checkList[i];
            } else {
              content += "," + this.checkList[i];
            }
          }
        }
      }

      ElMessageBox.confirm("是否确定终审不通过", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await api1.orderView(this.id, 4, content);

          if (res.code == 1) {
            // alert('成功');
            this.$message.success("成功");
            this.close();
            this.$emit("checksx", 1);
          } else {
            // alert(res.message);
            this.$message.warning(res.message);
          }
        })
        .catch(() => {
          this.$message.warning("取消");
        });
    },
    async fresh() {
      let newVal = this.id;
      let res_orderDetail = await api1.OrderDetail(newVal);
      this.detail = res_orderDetail.data;
      this.shenshu = this.detail.appealcontent;
      console.log(this.detail);
      let res_orderphotos = await api1.OrderPhotoSh(newVal);
      this.photo1 = res_orderphotos.data.photo1;
      this.photo2 = res_orderphotos.data.photo2;
      this.photo3 = res_orderphotos.data.photo3;
      this.photoempty = res_orderphotos.data.photoempty;
      this.photoall = res_orderphotos.data.photoall;

      let newarr = Object.values(this.photoall);
      console.log(newarr);
      this.srcList = newarr.map((item, index, arr) => {
        return item.now;
      });
    },

    // 点击状态
    typebtn() {
      console.log("状态状态:", this.$refs.checkbox);
      this.$emit("partbtn", this.id, this.$refs.checkbox);
      // this.$refs.checkbox.classList.add("cengji");
    },

    async appeal() {
      if (this.shenshu == "") {
        this.$message.warning("请输入你要申诉的内容");
        return;
      }

      let res = await api1.rescueAppeal(this.id, this.shenshu);

      if (res.code == 1) {
        this.$message.success("成功");
        this.fresh();
      } else {
        this.$message.warning(res.message);
      }
    },
  },
  watch: {
    issphoto(newval) {
      this.sphotos = newval;
      if (this.id != "") {
        this.fresh();
      }
    },
    sphotoid(newVal) {
      if (newVal != "") {
        this.checkList = [];
        this.id = newVal;
        this.fresh();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.car-p1 /deep/.el-checkbox-button__inner {
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  overflow: hidden;
  margin-top: -4px;
}

.box {
  width: 640px;
  // height: auto;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 60;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.box-main {
  width: 640px;
  height: 920px;
  background: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  // position: absolute;
  // right: 0;
  position: relative;
  overflow-y: scroll;
  padding-left: 30px;
  box-sizing: border-box;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 700px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;

  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.state {
  width: 500px;
  height: 40px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  //   background-color: skyblue;
}
.state-span {
  float: left;
  // line-height: 40px;
  width: 100px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
}
.state-p {
  display: inline-block;
  color: #2c68ff;
  margin: 0;
  padding: 0 12px;
  line-height: 40px;
  border: 1px solid #dadfe6;
  cursor: pointer;
}
.cartit {
  display: flex;
  height: 40px;
  align-items: center;
}
.owner {
  display: flex;
  width: 600px;
  font-size: 16px;
  margin-top: 22px;
  line-height: 21px;
  // margin-left: 34px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5983df;
  opacity: 1;
  float: left;
  clear: both;
}

.car-p1 {
  float: left;
  margin-right: 10px;
  // width: 125px;
  height: 125px;
  margin-bottom: 10px;
  // background-color: burlywood;
}
.car-photo {
  width: 400px;
}
.car-img {
  width: 120px;
  height: 100px;
  margin-top: 12px;
}
.owBottom {
  width: 120px;
  // overflow:;
  text-align: center;
  position: relative;
}
.hint {
  font-size: 14px;
  color: #727e96;
  margin-left: 22px;
}
.choice {
  vertical-align: middle;
  margin-left: 15px;
  margin-right: 5px;
}
.car-span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}
.abnormal {
  display: flex;
  width: 510px;
  // height: 40px;
  line-height: 35px;
  // margin-left: 34px;
  margin-top: 20px;
  //   background-color: gold;
  position: relative;
  float: left;
}
.yichang {
  width: 120px;
  font-size: 14px;
  line-height: 35px;
  color: #2a3346;
}
label {
  width: 70px;
  height: 19px;
  font-size: 14px;
  // margin-right: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  float: left;
}
.appeal {
  font-style: normal;
  width: 70px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  margin-left: 20px;
  color: #2c68ff;
  opacity: 1;
  float: left;
  clear: both;
}
.warn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 80px;
  float: left;
}

.btn {
  float: left;
  // width: 280px;
  height: 32px;
  opacity: 1;
  margin-left: 34px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 10px;
}
.set1 {
  width: 80px;
  height: 32px;
  background: #ff2c3e;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 20px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  // margin-left: 20px;
  border-radius: 2px;
  vertical-align: middle;
}

.el-checkbox {
  display: inline-block;
  text-align: center;
}
.owBottom /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0;
}

.car-p1 /deep/ .el-image {
  width: 120px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  box-sizing: border-box;
}
.cengji {
  z-index: 30;
}

.tu {
  width: 100%;
  z-index: 100;
  opacity: 0;
  position: absolute;
  left: 0;
}
.tutit {
  position: absolute;
  width: 100%;
  left: 0;
  font-size: 14px;
  color: #000;
  border: 1px solid #000;
}
</style>
