<template>
  <div @click.stop>
    <!-- 订单详情 -->
    <div class="box" v-if="looks || xinxis" ref="partbtn">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main" v-if="detail">
        <div class="main-nav">
          <h1>订单详情</h1>
          <img
            class="shua"
            src="../assets/img/ding/shua.png"
            alt=""
            @click="refresh(detail.订单号)"
          />
          <i class="xin" @click="refresh(detail.订单号)">刷新</i>
        </div>
        <!-- 订单号 -->
        <div v-if="hao">
          <span class="tiao"></span>
          <div class="order">
            订单号:{{ detail.订单号 }}
            <span class="take">{{ detail.服务状态 }}</span>
            <span class="take1" v-if="detail.rescue_style == 1">即时单</span>
            <span class="take2" v-if="detail.rescue_style == 2">预约单</span>
            <span class="take3" v-if="detail.rescue_style == 3">顺路单</span>
          </div>
          <div class="order-main">
            <div class="splitnum" v-if="detail.associate_order != ''">
              <img class="splitimg" src="../assets/img/cd/split.png" alt="" />
              <div
                class="splitnumbox"
                v-for="(value, index) in detail.associate_order"
                :key="index"
              >
                <span class="splitdanum" @click="gotochai(value)">{{
                  value
                }}</span>
              </div>
            </div>
            <div class="hezuo">
              <div class="p1">
                <span class="title">合作单位&#12288;</span>
                <span class="title-main">{{ detail.合作单位 }}</span>
              </div>
              <div class="xiangmu">
                <span class="title">服务项目</span>
                <i class="yanse">{{ detail.服务项目 }}</i>
              </div>
            </div>
            <div class="hezuo">
              <div class="p1">
                <span class="title">创建日期&#12288;</span>
                <span class="title-main">{{ detail.创建时间 }}</span>
              </div>
              <div class="xiangmu" v-if="detail.rescue_time">
                <span class="title">救援时间</span>
                <i class="yanse">{{ detail.rescue_time }}</i>
              </div>
            </div>

            <div class="diBox" v-if="detail.arrive">
              <label class="remTit">实际任务点</label>

              <div class="diTxt">{{ detail.arrive }}</div>
            </div>

            <div class="diBox" v-if="detail.arrive">
              <span class="remTit">实际目的地</span>

              <div class="diTxt">{{ detail.stop }}</div>
            </div>

            <div class="diBox" v-if="detail.close">
              <span class="remTit">取消位置&#12288;</span>

              <div class="diTxt">{{ detail.close }}</div>
            </div>

            <div class="diBox">
              <label class="remTit">任务点&#12288;&#12288;</label>

              <div class="diTxt">{{ detail.事故地点 }}</div>
            </div>

            <div class="diBox" v-if="detail.目的地">
              <span class="remTit">目的地&#12288;&#12288;</span>

              <div class="diTxt">{{ detail.目的地 }}</div>
            </div>

            <div class="diBox">
              <span class="remTit">接单人员&#12288;</span>
              <span class="diTxt1"
                >{{ detail.接单公司 }}{{ detail.接单人员
                }}<button
                  @click="call(detail.takemobile, detail.订单号)"
                  class="call"
                  v-show="getEmic != '' && detail.takemobile != ''"
                ></button
              ></span>
            </div>
            <div class="diBox" v-if="detail.automationUser">
              <span class="remTit">推送人员&#12288;</span>
              <span class="diTxt1">{{ detail.automationUser }}</span>
            </div>
            <div class="diBox" v-if="detail.closereason">
              <span class="remTit">关闭原因&#12288;</span>
              <span class="diTxt1">{{
                detail.tech_cancel == ""
                  ? detail.closereason
                  : detail.tech_cancel + "/" + detail.closereason
              }}</span>
            </div>

            <div class="remBox" v-if="detail.订单备注">
              <label class="remTit">订单备注&#12288;</label>
              <div class="remTxt" v-html="detail.订单备注"></div>
            </div>

            <div class="remBox">
              <label class="remTit" v-if="detail.spRemark">合作商备注</label>
              <div class="remTxt" v-html="detail.spRemark"></div>
            </div>
          </div>
        </div>
        <!-- 车主信息 -->
        <div v-if="hao">
          <div class="owner">
            <span class="tiao1"></span>
            车主信息
            <span
              style="
                background-color: red;
                color: white;
                font-size: 14px;
                padding: 1px 7px;
                font-weight: normal;
                margin-left: 10px;
              "
              v-if="detail.isBlack == 1"
              ><img
                src="../assets/img/black.png"
                style="width: 14px; margin-right: 10px"
              />黑名单 请注意</span
            >
          </div>
          <div class="owner-main">
            <div class="hezuo">
              <div class="p2">
                <label class="title">车主信息&#12288;</label>
                <span class="title-main"
                  >{{ detail.车主姓名 }}/{{ detail.手机号
                  }}<button
                    @click="call(detail.手机号, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </div>
              <div class="xiangmu">
                <label class="title">车牌号&#12288;</label>
                <span class="title-main2"
                  >{{ detail.车牌号
                  }}<span v-if="detail.ocrnumber != ''" style="color: red">
                    - {{ detail.ocrnumber }}</span
                  ></span
                >
              </div>
            </div>

            <div class="hezuo">
              <div class="p2">
                <span class="title">车型&#12288;&#12288;&#12288;</span>
                <span class="title-main">{{ detail.cartype }}</span>
              </div>

              <p class="xiangmu">
                <span class="title" v-if="detail.编号"
                  >编号&#12288;&#12288;</span
                >
                <span class="title-main2" v-if="detail.编号">{{
                  detail.编号
                }}</span>
              </p>
            </div>
            <div class="hezuo">
              <div class="p2">
                <span class="title">车架号&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.车架号
                  }}<span v-if="detail.ocrvin != ''" style="color: red">
                    - {{ detail.ocrvin }}</span
                  ></span
                >
              </div>
            </div>
            <div class="hezuo" v-if="detail.givename">
              <p class="p2">
                <span class="title">下单人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.givename }}/{{ detail.givemobile
                  }}<button
                    @click="call(detail.givemobile, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
            <div class="hezuo" v-if="detail.name1">
              <p class="p2">
                <span class="title">发车人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.name1 }}/{{ detail.tel1
                  }}<button
                    @click="call(detail.tel1, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
            <div class="hezuo" v-if="detail.name2">
              <p class="p2">
                <span class="title">接车人&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.name2 }}/{{ detail.tel2
                  }}<button
                    @click="call(detail.tel2, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>

            <div class="hezuo" v-if="detail.saleman">
              <p class="p2">
                <span class="title">业务员&#12288;&#12288;</span>
                <span class="title-main"
                  >{{ detail.saleman
                  }}<button
                    @click="call(detail.salemobile, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </p>
            </div>
          </div>
        </div>

        <!-- 相关费用 -->
        <div v-if="hao">
          <div class="owner">
            <span class="tiao1"></span>
            相关费用
          </div>

          <div class="feiBox">
            <div class="nor1">
              <span class="norm">收费标准</span>
              <span class="normMain">{{ detail.收费标准 }}</span>
              <span class="tuo" v-if="detail.免托">{{ detail.免托 }}</span>
            </div>
            <div class="nor2" v-if="detail.流转标准">
              <span class="norm">流转标准</span>
              <span class="normMain">{{ detail.流转标准 }}</span>
            </div>
            <div class="nor3" v-if="detail.预估到达距离">
              <span class="norm">到达距离</span>
              <span class="normMain" v-if="detail.预估到达距离"
                >预估：{{ detail.预估到达距离 }}</span
              >
              <span class="normMain" v-if="detail.到达距离"
                >实际：{{ detail.到达距离 }}</span
              >
            </div>
            <div class="nor3" v-if="detail.到达距离2">
              <span class="norm">到达距离</span>
              <span class="normMain" v-if="detail.到达距离2"
                >{{ detail.到达距离2 }}公里</span
              >
            </div>
            <div class="nor4" v-if="detail.目的地">
              <span class="norm">拖车距离</span>
              <span class="normMain" v-if="detail.预估拖车距离"
                >预估：{{ detail.预估拖车距离 }}/{{ detail.planprice }}元</span
              >
              <span class="normMain" v-if="detail.拖车距离"
                >实际：{{ detail.拖车距离 }}</span
              >
              <span class="normMain" v-if="detail.拖车距离2"
                >{{ detail.拖车距离2 }}公里</span
              >
            </div>
            <div class="nor4" v-if="detail.emptydistance">
              <span class="norm">空驶距离</span>
              <span class="normMain"> {{ detail.emptydistance }}公里 </span>
            </div>
            <div class="nor4" v-if="detail.dk_level">
              <span class="norm">地库</span>
              <span class="normMain">{{ detail.dk_level }}</span>
              <span class="normMain"
                >{{ detail.dk_num }}层/{{ detail.dk_price }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.aw_level">
              <span class="norm">辅助轮</span>
              <span class="normMain">{{ detail.aw_level }}</span>
              <span class="normMain"
                >{{ detail.aw_num }}个/{{ detail.aw_price }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.opLevel != ''">
              <span class="norm">外协价格</span>
              <span class="normMain">{{ detail.opLevel }}</span>
            </div>
            <div class="nor4" v-if="detail.opDk != ''">
              <span class="norm">外协地库</span>
              <span class="normMain">{{ detail.opDk }}</span>
              <span class="normMain"
                >{{ detail.dk_num }}层/{{ detail.opDkPrice }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.opAw">
              <span class="norm">外协小轮</span>
              <span class="normMain">{{ detail.opAw }}</span>
              <span class="normMain"
                >{{ detail.aw_num }}个/{{ detail.opAwPrice }}元</span
              >
            </div>
          </div>
        </div>

        <!-- 费用明细 -->
        <div v-if="hao">
          <div class="owner" v-if="detail.显示费用">
            <span class="tiao1"></span>
            费用明细
          </div>
          <table v-if="detail.显示费用">
            <tbody>
              <tr>
                <td class="standard1">基准费用</td>
                <td class="price1">
                  <span style="float: left; margin-left: 5px; color: #dadfe6">{{
                    detail.收费标准2
                  }}</span
                  >¥{{ detail.基准价格 }}
                </td>
              </tr>
              <tr v-if="detail.夜间加价">
                <td class="standard">夜间加价</td>
                <td class="price">¥{{ detail.夜间加价 }}</td>
              </tr>
              <tr v-if="detail.附加费用">
                <td class="standard">辅助费用</td>
                <td class="price">¥{{ detail.附加费用 }}</td>
              </tr>
              <tr v-if="detail.车主支付 && detail.车主支付类型">
                <td class="standard2">{{ detail.车主支付类型 }}</td>
                <td class="price2">¥{{ detail.车主支付 }}</td>
              </tr>
              <tr v-if="detail.路桥费">
                <td class="standard2">路桥费</td>

                <td class="price2">
                  <span style="float: left; margin-left: 5px; color: #dadfe6">{{
                    detail.路桥费类型
                  }}</span
                  >¥{{ detail.路桥费 }}
                </td>
              </tr>
              <tr v-if="detail.bridge_edit != ''">
                <td class="standard2">路桥费(增)</td>
                <td class="price2">¥{{ detail.bridge_edit }}</td>
              </tr>
              <tr v-if="detail.流量费">
                <td class="standard2">流量费</td>
                <td class="price2">-¥{{ detail.流量费 }}</td>
              </tr>
              <tr v-if="detail.流转费用">
                <td class="standard2">流转费用</td>
                <td class="price2">
                  <span
                    style="
                      float: left;
                      margin-left: 5px;
                      color: #dadfe6;
                      font-weight: normal;
                    "
                    >{{ detail.流转标准 }}</span
                  >-¥{{ detail.流转费用 }}
                </td>
              </tr>
              <tr v-if="detail.总费用">
                <td class="standard2">总费用</td>
                <td class="price2">¥{{ detail.总费用 }}</td>
              </tr>
              <tr v-if="detail.takecommission">
                <td class="standard2">内部提成</td>
                <td class="price2">
                  <span
                    style="
                      float: left;
                      margin-left: 5px;
                      color: #dadfe6;
                      font-weight: normal;
                    "
                    >{{ detail.takecommissionpercent }}</span
                  >¥{{ detail.takecommission }}
                </td>
              </tr>
              <tr v-if="detail.opPrice != ''">
                <td class="standard2">外协费用</td>
                <td class="price2">¥{{ detail.opPrice }}元</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 人员轨迹 -->
        <div class="owner1">
          <span class="owner2">
            <span class="tiao2"></span>
            人员轨迹
          </span>
          <div
            class="state"
            @mouseenter="mouseenterFun"
            @mouseleave="mouseleaveFun"
          >
            <!--<span class="stateTitle">轨迹点说明</span>
            <img class="stateImg" src="../assets/img/cd/ti.png" alt="" />-->
          </div>

          <button class="damap" @click="fangda" v-show="hao == true">
            放大
          </button>
          <button class="damap" @click="huan" v-show="hao == false">
            还原
          </button>

          <div class="yinState" v-show="yin"></div>
        </div>

        <!-- 地图 -->
        <div class="map-box" ref="damapbox">
          <div class="map-img">
            <div id="container" ref="ditubox"></div>
          </div>

          <div class="mapright">
            <div :class="hao == false ? 'mapMain' : ''">
              <div :class="hao == false ? 'danmain' : ''">
                <p class="map-p jie1" v-if="detail.接单时间">
                  <img
                    class="position"
                    src="../assets/img/slices3/icon-coordinate-blue-def(1).png"
                    alt=""
                  />
                  <span class="actual2">接单时间:</span>
                  <span class="km2">{{ detail.接单时间 }}</span>
                </p>

                <p class="map-p">
                  <span
                    class="main-m1"
                    v-if="detail.预估到达距离 && detail.到达时间"
                    >接单 预估{{ detail.预估到达距离 }}</span
                  >
                  <span
                    class="main-mn"
                    v-if="detail.到达距离 && detail.到达时间"
                    >实际{{ detail.到达距离 }}</span
                  >
                  <span
                    class="main-mn"
                    v-if="detail.到达距离2 && detail.到达时间"
                    >{{ detail.到达距离2 }}公里</span
                  >
                </p>
              </div>

              <div :class="hao == false ? 'danmain' : ''">
                <p class="map-p jie2" v-if="detail.到达时间">
                  <img
                    class="position1"
                    src="../assets/img/slices3/icon-coordinate-blue-def(1).png"
                    alt=""
                  />
                  <span class="actual2">到达现场时间:</span>
                  <span class="km2">{{ detail.到达时间 }}</span>
                </p>

                <p class="map-p">
                  <span
                    class="main-m1"
                    v-if="detail.预估拖车距离 && detail.到达时间"
                    >拖车 预估{{ detail.预估拖车距离 }}</span
                  >
                  <span
                    class="main-mn"
                    v-if="detail.拖车距离 && detail.到达时间"
                    >实际{{ detail.拖车距离 }}</span
                  >
                  <span
                    class="main-mn"
                    v-if="detail.拖车距离2 && detail.到达时间"
                    >{{ detail.拖车距离2 }}公里</span
                  >
                </p>
              </div>

              <div :class="hao == false ? 'danmain' : ''">
                <p class="map-p jie3" v-if="detail.完成作业时间">
                  <img
                    class="position2"
                    src="../assets/img/slices3/icon-coordinate-blue-def(1).png"
                    alt=""
                  />
                  <span class="actual2">完成作业时间:</span>
                  <span class="km2">{{ detail.完成作业时间 }}</span>
                </p>
              </div>

              <div :class="hao == false ? 'danmain' : ''">
                <p class="map-p1 jie4" v-if="detail.完成作业时间">
                  <img
                    class="position3"
                    src="../assets/img/slices3/icon-coordinate-blue-def(1).png"
                    alt=""
                  />
                  <span class="actual2">服务完成时间:</span>
                  <span class="km2">{{ detail.服务完成时间 }}</span>
                </p>
              </div>
            </div>
            <div
              :class="hao == false ? 'shijian' : 'duration'"
              v-if="detail.调度时长 || detail.接单时长"
            >
              <p class="duration-p" v-if="detail.调度时长">
                <img
                  class="duration-img"
                  src="../assets/img/slices3/line-icon-one.png"
                  alt=""
                />
                <span class="duration-span">调度</span>
                <span class="duration-time">{{ detail.调度时长 }}</span>
              </p>
              <p class="duration-p" v-if="detail.接单时长">
                <span class="duration-span">接单</span>
                <span class="duration-time">{{ detail.接单时长 }}</span>
              </p>
              <p class="duration-p" v-if="detail.到达时长">
                <img
                  class="duration-img"
                  src="../assets/img/slices3/line-icon-two.png"
                  alt=""
                />
                <span class="duration-span">到达</span>
                <span class="duration-time">{{ detail.到达时长 }}</span>
              </p>
              <p class="duration-p" v-if="detail.作业时长">
                <span class="duration-span">作业</span>
                <span class="duration-time">{{ detail.作业时长 }}</span>
              </p>
              <p class="duration-p" v-if="detail.拖车时长">
                <img
                  class="duration-img"
                  src="../assets/img/slices3/line-icon-three.png"
                  alt=""
                />
                <span class="duration-span">拖车</span>
                <span class="duration-time">{{ detail.拖车时长 }}</span>
              </p>
              <p class="duration-p" v-if="detail.卸车时长">
                <span class="duration-span">卸车</span>
                <span class="duration-time">{{ detail.卸车时长 }}</span>
              </p>
              <p class="duration-p1" v-if="detail.支付时长">
                <img
                  class="duration-img"
                  src="../assets/img/slices3/line-icon-three.png"
                  alt=""
                />
                <span class="duration-span">支付</span>
                <span class="duration-time">{{ detail.支付时长 }}</span>
              </p>
            </div>
          </div>
        </div>

        <!-- 操作日志 -->
        <div v-if="hao">
          <div class="owner" v-if="log">
            <span class="tiao1"></span>
            操作日志
          </div>
          <div class="owner-log" v-if="log">
            <div class="rizhi" v-for="(value, index) in log" :key="index">
              <div class="rileft">
                <div class="rizhiTit">{{ value.time }}</div>
                <div class="rizhiMain">{{ value.name }}</div>
                <div class="rizhiTxt">【{{ value.action }}】</div>
              </div>

              <audio
                :src="value.src"
                controls
                v-if="value.src != '' && value.src != undefined"
              ></audio>
            </div>
          </div>
        </div>

        <!-- 通话录音 -->
        <div v-if="hao">
          <div class="owner" v-if="emiclog.length > 0">
            <span class="tiao1"></span>
            通话录音
          </div>
          <div class="owner-log" v-if="emiclog.length > 0">
            <div class="rizhi" v-for="(value, index) in emiclog" :key="index">
              <div class="rileft">
                <div class="rizhiTit">{{ value.time }}</div>
                <div class="rizhiMain">{{ value.user }}</div>
              </div>

              <div class="rizhiTxt">【{{ value.tel }}】</div>
              <audio :src="value.url" controls></audio>
            </div>
          </div>
        </div>

        <!-- 服务凭证 -->
        <div v-if="hao">
          <div
            class="owner"
            v-if="
              detail.status >= 6 &&
              (photo1.length ||
                photo2.length ||
                photo3.length ||
                photoempty.length ||
                photoplus.length)
            "
          >
            <span class="tiao1"></span>
            服务凭证
          </div>
          <div class="owner-photo" v-if="detail.status >= 6 && photo1.length">
            <p class="p3">
              <span class="title">现场照</span>
            </p>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo1" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index"
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <span class="car-span" v-if="item.name.length < 10">{{
                  item.name
                }}</span>
                <marquee scrollamount="3" v-if="item.name.length > 10">{{
                  item.name
                }}</marquee>
              </div>
            </div>
          </div>
          <div class="owner-photo" v-if="detail.status >= 6 && photo2.length">
            <p class="p3">
              <span class="title">作业照</span>
            </p>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo2" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index + photo1.length"
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <span class="car-span" v-if="item.name.length < 10">{{
                  item.name
                }}</span>
                <marquee scrollamount="3" v-if="item.name.length > 10">{{
                  item.name
                }}</marquee>
              </div>
            </div>
          </div>

          <div class="owner-photo" v-if="photo3.length">
            <p class="p3">
              <span class="title">完成照</span>
            </p>
            <div class="car-photo">
              <div class="car-p1" v-for="(item, index) in photo3" :key="index">
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="index + photo1.length + photo2.length"
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <span class="car-span" v-if="item.name.length < 10">{{
                  item.name
                }}</span>
                <marquee scrollamount="3" v-if="item.name.length > 10">{{
                  item.name
                }}</marquee>
              </div>
            </div>
          </div>

          <div
            class="owner-photo"
            v-if="detail.status >= 6 && photoempty.length"
          >
            <p class="p3">
              <span class="title">空驶照</span>
            </p>
            <div class="car-photo">
              <div
                class="car-p1"
                v-for="(item, index) in photoempty"
                :key="index"
              >
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="
                    index + photo1.length + photo2.length + photo3.length
                  "
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <span class="car-span">{{ item.name }}</span>
              </div>
            </div>
          </div>

          <div
            class="owner-photo"
            v-if="detail.status >= 6 && photoplus.length"
          >
            <p class="p3">
              <span class="title">附加服务</span>
            </p>
            <div class="car-photo">
              <div
                class="car-p1"
                v-for="(item, index) in photoplus"
                :key="index"
              >
                <el-image
                  :src="item.now + '?x-oss-process=style/A_X_S'"
                  :preview-src-list="srcList"
                  :initial-index="
                    index +
                    photo1.length +
                    photo2.length +
                    photo3.length +
                    photoempty.length
                  "
                  fit="cover"
                  @click="clickimg(item.now)"
                >
                </el-image>
                <span class="car-span">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 音频 -->
        <!-- <audio src="" controls></audio> -->



        <div class="btn" v-if="hao">

          <button
            class="set"
            style="background:green;"
            @click="changeKm(detail.订单号)"
            v-if="detail.canChangeKm && !getlook"
          >
            修改公里数
          </button>


          <button
            class="set"
            v-if="detail.可审核 && detail.isReview < 2 && !getlook"
            @click="filfull()"
          >
            审核
          </button>
          <button class="set" v-if="!getlook && detail.可补传" @click="spp()">
            补传照片
          </button>
          <button
            class="cancel"
            v-if="detail.canEdit && !getlook"
            @click="edit(detail.订单号)"
          >
            编辑
          </button>
          <button class="cancel" @click="beizhu(detail.订单号)" v-if="!getlook">
            备注
          </button>

          <button class="cancel" @click="baobei(detail.订单号)" v-if="!getlook && detail.canReport">
            报备
          </button>
          <button
            class="xiangpai"
            v-if="detail.status < 3 && detail.可调度 && !getlook"
            @click="zhipai(detail.订单号)"
          >
            指派
          </button>
          <button
            class="xiangpai"
            v-if="
              detail.status >= 3 &&
              detail.status < 7 &&
              detail.可调度 &&
              !getlook
            "
            @click="zhipai(detail.订单号)"
          >
            改派
          </button>

          <button
            class="cancel2"
            @click="chepai(detail.订单号)"
            v-if="detail.editvin && !getlook"
          >
            车牌车架修改
          </button>

          <button
            class="cancel"
            @click="chaibtn(detail.订单号)"
            v-if="detail.canOpenOrder && !getlook"
          >
            拆分
          </button>



        </div>
      </div>
    </div>
  </div>

  <ding-gai :iszhi="zhi" :partId="rescueId"></ding-gai>
  <buchuan :issphoto="issphotos" :sphotoid="rescueId" @partsx="sx2"></buchuan>
  <!-- <check :isaudio="audio" :rescueIdcheck="rescueId" @checkedsx="sx2"></check> -->
  <rescue-edit
    :isshowedit="showedit"
    :rescueIdx="rescueIdx"
    @editsx="sx1"
  ></rescue-edit>
  <remarks :isBei="isbeis" :remarkid="remarksid" @remarksx="sx"></remarks>
  <report :isreports="baobeis" :isbaobeiid="baobeiid" @baobeisx="sx"></report>
  <split
    v-if="chaifen"
    @close="suansplit"
    @close2="suansplit2"
    :splitids="chaifenid"
  ></split>
  <modifypartKm
      v-if="xiupartKm"
      :idK="xiupartKmId"
      @closepartKm="closepartKm"
    ></modifypartKm>
  <farme
    :iscarnum="iscarnum"
    :carmain="carmain"
    v-if="caryin"
    @overframe="overframe"
  ></farme>
</template>

<script>
// import Check from "./check.vue";
import rescueEdit from "../components/rescueEdit.vue";
import modifypartKm from "../components/modifypartKm.vue";
import dingGai from "../components/dingGai.vue";
import remarks from "../components/remarks.vue";
import report from "../components/report.vue";
import buchuan from "./buchuan.vue";
import farme from "./farme.vue";
import * as api1 from "../api/orderDetail";
import * as ola from "../api/olawebsock.js";
//import AMapLoader from '@amap/amap-jsapi-loader';
import { shallowRef } from "@vue/reactivity";
import AMap from "AMap";
import Split from "./split.vue";

export default {
  props: ["islook", "id", "isXin"],
  components: { rescueEdit, remarks, dingGai, Split, buchuan, farme,modifypartKm,report },
  data() {
    return {
      chaifen: false,
      yin: false,
      audio: 0,
      looks: this.islook,
      xinxis: this.isXin,
      detail: {},
      log: [],
      zhipaiId: "",
      photo1: [],
      photo2: [],
      photo3: [],
      photoempty: [],
      photoplus: [],
      photoall: [],
      map: null,
      center: [121.483874, 29.861542],
      showedit: 0,
      rescueId: this.id,
      srcList: [],
      remarksid: "",
      isbeis: 0,
      zhi: 0,
      hao: true,
      emicreg: "",
      emiclog: [],
      chaifenid: "",
      issphotos: 0,
      caryin: false,
      carmain: {},
      isNewSh: "",
      xiupartKm:false,
      xiupartKmId:'',
      baobeis:0,
      baobeiid:'',
    };
  },
  async created() {},

  methods: {
    baobei(id){
      this.baobeis++;
      if (this.baobeis == 3) {
        this.baobeis = 1;
      }
      this.baobeiid = id;
    },  
    close() {
      this.looks = false;
      this.xinxis = false;
      this.hao = true;
      this.$refs.damapbox.classList.remove("damapbox");
      this.$refs.ditubox.classList.remove("ditubox");
      // this.look = !this.look;
    },
    filfull() {
      console.log("点击审核", this.isNewSh);
      this.$emit("checkbtn", this.detail.订单号, this.$refs.partbtn,this.isNewSh);
    },
    chepai(e) {
      this.caryin = true;
      this.iscarnum = e;
      this.carmain.hezuotit = this.detail.合作单位;
      this.carmain.time = this.detail.rescue_time;
      this.carmain.renwu = this.detail.事故地点;
      this.carmain.mudi = this.detail.目的地;
      this.carmain.chejia = this.detail.车牌号;
    },
    overframe() {
      this.caryin = false;
      this.refresh(this.rescueId);
    },
    spp() {
      this.issphotos++;
      if (this.isphotos == 3) {
        this.issphotos = 0;
      }
    },
    chaibtn(id) {
      console.log(id);
      this.chaifenid = id;
      this.chaifen = true;
    },
    suansplit() {
      //this.refresh();
      this.chaifen = false;
    },
    suansplit2() {
      this.refresh(this.rescueId);
      this.chaifen = false;
    },
    fangda() {
      this.hao = false;
      this.$refs.damapbox.classList.add("damapbox");
      this.$refs.ditubox.classList.add("ditubox");
    },
    huan() {
      this.hao = true;
      this.$refs.damapbox.classList.remove("damapbox");
      this.$refs.ditubox.classList.remove("ditubox");
    },

    sx() {
      this.refresh(this.rescueId);
      this.remarksid = "";
    },
    sx1() {
      this.refresh(this.rescueId);
      this.rescueIdx = "";
    },
    sx2() {
      this.refresh(this.rescueId);
    },
    mouseenterFun() {
      this.yin = true;
    },
    mouseleaveFun() {
      this.yin = false;
    },
    gotochai(id) {
      this.refresh(id);
    },
    async refresh(id) {
      let res_orderDetail = await api1.OrderDetail(id);
      this.detail = res_orderDetail.data;
      console.log("订单详情：", this.detail);

      this.isNewSh = res_orderDetail.data.isNewSh;

      let res_orderLog = await api1.OrderLog(id);
      this.log = res_orderLog.data.logs;

      let res_emiclog = await api1.EmicLog(id);
      this.emiclog = res_emiclog.data;
      console.log(this.emiclog);

      let res_orderphotos = await api1.OrderPhotoNew(id);
      this.photo1 = res_orderphotos.data.photo1;
      this.photo2 = res_orderphotos.data.photo2;
      this.photo3 = res_orderphotos.data.photo3;
      this.photoempty = res_orderphotos.data.photoempty;
      this.photoplus = res_orderphotos.data.photoplus;
      this.photoall = res_orderphotos.data.photoall;

      let newarr = Object.values(this.photoall);
      console.log(newarr);
      this.srcList = newarr.map((item, index, arr) => {
        return item.now;
      });

      console.log(res_orderphotos);

      this.initMap();
    },
    zhipai(id) {
      console.log("点击了-------------");
      this.zhi++;
      if (this.zhi == 3) {
        this.zhi = 1;
      }
      this.rescueId = id;
      this.looks = false;
    },
    initMap() {
      if (
        this.detail.事故点经纬度 != undefined &&
        this.detail.事故点经纬度 != ""
      ) {
        var a = this.detail.事故点经纬度.split(",");
        this.center = [a[0], a[1]];
      }
      let map = new AMap.Map("container", {
        center: this.center,
        resizeEnable: true,
        zoom: 10,
      });
      var infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
        isCustom: true,
        closeWhenClickMap: true,
      });

      if (
        this.detail.事故点经纬度 != undefined &&
        this.detail.事故点经纬度 != ""
      ) {
        var marker = new AMap.Marker({
          position: new AMap.LngLat(a[0], a[1]),
          // title: this.detail.事故地点,
          // icon: "https://dispatch-test.nituowola.com/img/amap/address.png",
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/address.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.事故地点 +
            "</span>" +
            "</div>",
        });

        //  var driver = new AMap.Marker({
        //       position: new AMap.LngLat(item.lng, item.lat),
        //       map: map,
        //       content: '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/busy.png" style="transform:rotate('+item.angel+'deg)"></br>'+carnumber+'</div>',
        //       //icon: "https://dispatch-test.nituowola.com/img/amap/busy.png",
        //       //angle: item.angel,
        //     });

        // marker.content =
        //   "<div class='xx'>" + "<div class='xxx'>任务点</div></div>";
        // marker.on("click", markerClick);
        map.add(marker);
      }

      if (
        this.detail.目的地经纬度 != undefined &&
        this.detail.目的地经纬度 != ""
      ) {
        var b = this.detail.目的地经纬度.split(",");
        var markerb = new AMap.Marker({
          position: new AMap.LngLat(b[0], b[1]),
          map: map,
          content:
            this.detail.stop == ""
              ? '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/destination.png"></br>' +
                '<span class="mapaddress">' +
                this.detail.目的地 +
                "</span>" +
                "</div>"
              : '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/destination1.png"></br>' +
                '<span class="mapaddress">' +
                this.detail.目的地 +
                "</span>" +
                "</div>",
        });

        map.add(markerb);
      }

      if (
        this.detail.出发点经纬度 != undefined &&
        this.detail.出发点经纬度 != ""
      ) {
        var c = this.detail.出发点经纬度.split(",");
        var markerc = new AMap.Marker({
          position: new AMap.LngLat(c[0], c[1]),
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/take.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.出发点 +
            "</span>" +
            "</div>",
          //icon: "https://dispatch-test.nituowola.com/img/amap/take.png",
        });

        map.add(markerc);
      }

      if (
        this.detail.arrivelocation != undefined &&
        this.detail.arrivelocation != ""
      ) {
        var d = this.detail.arrivelocation.split(",");
        var markerd = new AMap.Marker({
          position: new AMap.LngLat(d[0], d[1]),
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/arrive.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.arrive +
            "</span>" +
            "</div>",
        });

        map.add(markerd);
      }

      if (
        this.detail.startlocation != undefined &&
        this.detail.startlocation != ""
      ) {
        var e = this.detail.startlocation.split(",");
        var markere = new AMap.Marker({
          position: new AMap.LngLat(e[0], e[1]),
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/start.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.start +
            "</span>" +
            "</div>",
        });

        map.add(markere);
      }

      if (
        this.detail.stoplocation != undefined &&
        this.detail.stoplocation != ""
      ) {
        var f = this.detail.stoplocation.split(",");
        var markerf = new AMap.Marker({
          position: new AMap.LngLat(f[0], f[1]),
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/stop.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.stop +
            "</span>" +
            "</div>",
        });
        map.add(markerf);
      }

      if (
        this.detail.closelocation != undefined &&
        this.detail.closelocation != ""
      ) {
        var close = this.detail.closelocation.split(",");
        var markerclose = new AMap.Marker({
          position: new AMap.LngLat(close[0], close[1]),
          map: map,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/close.png"></br>' +
            '<span class="mapaddress">' +
            this.detail.close +
            "</span>" +
            "</div>",
        });
        map.add(markerclose);
      }

      if (
        this.detail.nowlocation != undefined &&
        this.detail.nowlocation != ""
      ) {
        var markerg = new AMap.Marker({
          position: new AMap.LngLat(
            this.detail.nowlocation.lng,
            this.detail.nowlocation.lat
          ),
          title: "技师当前位置",
          icon: "https://dispatch-test.nituowola.com/img/amap/car.png",
        });

        markerg.content =
          "<div class='xx'>" + "<div class='xxx'>技师当前位置</div></div>";
        markerg.on("click", markerClick);
        map.add(markerg);
      }

      if (
        this.detail.take_polyline != "" &&
        this.detail.take_polyline != undefined
      ) {
        var takeline = new AMap.Polyline({
          map: map,
          path: this.detail.take_polyline,
          borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: "green", // 线条颜色
          lineJoin: "round", // 折线拐点连接处样式     //设置线覆盖物路径
        });
        console.log(this.detail.take_polyline);
        //map.add(takeline);
      }

      if (this.detail.polyline != "" && this.detail.polyline != undefined) {
        var line = new AMap.Polyline({
          map: map,
          path: this.detail.polyline, //设置线覆盖物路径
          strokeColor: "blue", //线颜色
          strokeWeight: 3, //线宽
          strokeStyle: "solid", //线样式
          strokeDasharray: [10, 5], //补充线样式
        });
        console.log(this.detail.polyline);
      }

      if (this.detail.planline != "" && this.detail.planline != undefined) {
        var line = new AMap.Polyline({
          map: map,
          path: this.detail.planline, //设置线覆盖物路径
          strokeColor: "gray", //线颜色
          strokeWeight: 3, //线宽
          strokeStyle: "solid", //线样式
          strokeDasharray: [10, 5], //补充线样式
        });
        console.log(this.detail.planline);
      }
      function markerClick(e) {
        console.log("--------click", e);
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, e.target.getPosition());
      }
    },
    edit(id) {
      this.showedit++;
      if (this.showedit == 3) {
        this.showedit = 1;
      }
      this.rescueIdx = id;
    },
    beizhu(id) {
      this.isbeis++;
      if (this.isbeis == 3) {
        this.isbeis = 1;
      }
      this.remarksid = id;
    },
    call(tel, str) {
      let ue = this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber: tel.toString(),
        loginType: mode,
        extras: str,
      });
    },
    clickimg(e) {},
    changeKm(id){
      console.log(id);
      this.xiupartKmId = id;
      this.xiupartKm = true;
    },
    closepartKm()
    {
      this.xiupartKm = false;
      this.refresh(this.rescueId);
    }
    // 详情指派按钮
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getlook() {
      return this.$store.state.lookonly;
    },
  },
  watch: {
    islook(newval) {
      console.log("---", newval);
      this.looks = newval;
      this.refresh(this.id);
    },
    isXin(newval) {
      console.log("---", newval);
      this.xinxis = newval;
      this.refresh(this.id);
    },
    id(newval) {
      this.rescueId = newval;
    },
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
};
</script>

<style lang="less" scoped>
.norm,
.roam,
.tuo1,
.tuo2 {
  width: 100px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background-color: #f8fafc;
  color: #2a3346;
  font-size: 16px;
  // border: 1px solid #DADFE6;
  border-left: 1px solid #dadfe6;
  border-right: 1px solid #dadfe6;
  box-sizing: border-box;
}
.norm {
  border-right: 1px solid #dadfe6;
}
.roam {
  border-top: 0;
}
.nor1,
.nor2,
.nor3,
.nor4 {
  width: 637px;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.nor2 {
  border-top: 0;
}
.nor3 {
  border-top: 0;
}
.nor4 {
  border-top: 0;
}
.normMain,
.tuo,
.roamMain,
.tuo1Main,
.yuMain,
.tuo2Main {
  height: 50px;
  line-height: 50px;
  vertical-align: top;
  padding-left: 10px;
  display: inline-block;
  // padding-right: 15px;
  display: inline-block;
  font-size: 14px;
  // border: 1px solid #DADFE6;
  box-sizing: border-box;
  border-left: 0;
}
.tuo {
  color: #2c68ff;
}
.normMain {
  width: 250px;
  //  border-left: 1px solid #DADFE6;
  //  border-right: 1px solid #dadfe6;
}

.tuo1Main {
  width: 165px;
  border-right: 1px solid #dadfe6;
}
.roamMain,
.tuo2Main {
  width: 180px;
  // border-left: 1px solid #dadfe6;
}
.stateTitle {
  color: #fa8c16;
  font-size: 14px;
  vertical-align: middle;
  margin-right: 4px;
}
.stateImg {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}
.state {
  display: inline-block;
  cursor: pointer;
}
.yinState {
  width: 220px;
  height: 110px;
  background-color: #fff;
  border-radius: 2px;
  margin-left: 82px;
  margin-top: 7px;
  // position: relative;
  position: absolute;
  // top: 45px;
  left: 50px;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  z-index: 99;
  padding: 0px;
  background-image: url("../assets/img/icon/dian.png");
  background-size: 100% 100%;
}
.yinState::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 88px;
  top: -14px;
  border: 7px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.yinUl {
  list-style: none;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.yinUl li {
  margin-left: 5px;
  float: left;
  margin-top: 5px;
}
.yinImg {
  width: 16px;
  height: 21px;
  vertical-align: middle;
  margin-right: 4px;
}
.yinTitle {
  font-size: 10px;
  vertical-align: middle;
}

// 相关费用
.feiBox {
  margin-left: 30px;
  // margin-top: 20px;
}
.feiTop {
  width: 636px;
  height: 50px;
  // border-bottom: 1px solid #DADFE6;
}
.feiBottom {
  height: 50px;
}

.toll {
  float: left;
  width: 100px;
  height: 50px;
  color: #2a3346;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  background-color: #f8fafc;
  border: 1px solid #dadfe6;
  box-sizing: border-box;
}
.milage {
  float: left;
  width: 200px;
  height: 50px;
  font-size: 14px;
  border: 1px solid #dadfe6;
  border-left: 0;
  padding-left: 15px;
  line-height: 50px;
  box-sizing: border-box;
}
.avoid {
  float: left;
  width: 115px;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  border-left: 0;
  box-sizing: border-box;
}
.milage1 {
  width: 100px;
  line-height: 50px;
  height: 50px;
  float: left;
  text-align: center;
  font-size: 14px;
  border: 1px solid #dadfe6;
  box-sizing: border-box;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 330px;
  height: 300px;
}

.duration {
  float: left;
  margin-top: 12px;
  width: 280px;
  // height: 98px;
  padding-left: 25px;

  background: #f8fafc;
  opacity: 1;
  border-radius: 2px;
  // position: absolute;
  // top: 185px;
  // right: 60px;
}
.duration-p1 {
  width: 100%;
  padding: 5px 0;
  float: left;
}
.duration-p {
  width: 50%;
  padding: 5px 0;
  float: left;
}
.duration-img,
.duration-img1 {
  width: 14px;
  height: 14px;
  line-height: 16px;
  margin-right: 10px;
  opacity: 1;
}
.duration-img {
  border-radius: 50%;
}
.duration-span {
  margin-right: 10px;
}

.box {
  width: 750px;
  // height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 33;
}

.cha {
  float: left;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 70px;
}
.box-main {
  width: 720px;
  height: 920px;
  //   background: orange;
  background-color: #fff;
  margin-left: 40px;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  box-sizing: border-box;
  overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  cursor: pointer;
  padding-left: 20px;
}
h1 {
  padding-top: 20px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 80px;
  color: #2a3346;
  opacity: 1;
}
i {
  font-style: normal;
}
.xin {
  // width: 32px;
  height: 21px;
  font-style: normal;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2c68ff;
  position: absolute;
  top: 58px;
  right: 40px;
  opacity: 1;
}
.shua {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50px;
  right: 80px;
}
.order {
  // width: 380px;
  height: 21px;
  font-size: 16px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}

.take,
.take1,
.take2,
.take3 {
  display: inline-block;
  // width: 70px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}
.take1 {
  color: #2c68ff;
  background: #e8f3fe;
}
.take2 {
  color: #27cc8b;
  background: #eefbf4;
}
.take3 {
  color: #fff6e9;
  background: #ff9100;
}
.order-main {
  //width: 510px;
  height: auto;
  opacity: 1;
  background-color: #fff;
}
.hezuo {
  display: flex;
}
.p1 {
  margin-left: 30px;
  // padding-left: 7px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
  display: flex;
}
.jiedan {
  width: 500px;
}
.xiangmu {
  // margin-left: 20px;
  display: flex;
}
.yanse {
  color: #2c68ff;
}

.remBox,
.diBox {
  display: flex;
  width: 730px;
  // flex-direction: column;
  margin-left: 30px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

// .diBox {
//   margin-bottom: 10px;
// }
.remTit {
  margin-right: 30px;
}

.diTxt {
  color: #2c68ff;
}
.remTxt {
  width: 500px;
}
.diTxt,
.diTxt1 {
  width: 500px;
}

.p2 {
  margin-left: 30px;
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
  display: flex;
}
.p3 {
  margin-left: 30px;
}
.title {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 30px;
}

.title-i {
  font-size: 14px;
  color: #2c68ff;
  margin-left: 10px;
}
.title1 {
  color: #2c68ff;
}
.owner-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title-main,
.title-main2 {
  //height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.title-main {
  width: 250px;
}
.title-main2 {
  width: 126px;
}
.tit-b {
  margin-left: 60px;
}

.icon {
  width: 5px;
  height: 16px;
  font-size: 12px;
  margin-left: -5px;
  font-family: MicrosoftYaHei;
  line-height: 17px;
  color: #ff0000;
  opacity: 1;
}
.owner,
.peoper,
.owner1 {
  width: 500px;
  height: 21px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 21px;
  padding-left: 7px;
  margin-left: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.owner {
  position: relative;
  margin-top: 20px;
}
.owner1 {
  display: flex;
  align-items: center;
}
.owner-log {
  // width: 500px;
  // background-color: pink;
  max-height: 200px;
  overflow-y: auto;
}
.tiao,
.tiao1,
.tiao2 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 93px;
  left: 20px;
}
.tiao1 {
  position: absolute;
  top: 4px;
  left: 0;
}
.owner2 {
  float: left;
  margin-right: 70px;
  padding-left: 7px;
  position: relative;
}
.tiao2 {
  position: absolute;
  top: 4px;
  left: 0;
}
table,
table tr,
table tr td {
  border: 1px solid #dadfe6;
}
table {
  margin-left: 30px;
  // margin-top: 20px;
  min-height: 25px;
  line-height: 25px;
  border-collapse: collapse;
  padding: 2px;
}
tr {
  height: 50px;
}
td {
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}

.standard1,
.standard,
.standard2 {
  width: 236px;
  height: 50px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}
.standard1,
.price1 {
  background-color: #f8fafc;
}
.standard2 {
  color: #2a3346;
  font-weight: bold;
}
.price,
.price1,
.price2 {
  width: 401px;
  height: 50px;
  padding-right: 20px;
  box-sizing: border-box;
  text-align: right;
}
.price2 {
  color: #2c68ff;
  font-weight: bold;
}

.depart {
  display: inline-block;
  width: 8px;
  height: 13px;
  margin-right: 4px;
}
.depart-main {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.quan,
.quan1 {
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-top: 6px;
  border-radius: 50%;
  opacity: 1;
  box-sizing: border-box;
}
.quan {
  border: 3px solid #2c68ff;
}
.quan1 {
  border: 3px solid #27cc8b;
}
.site {
  float: left;
  margin-right: 27px;
}

.actual2,
.km2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.actual2 {
  margin-left: 20px;
}
.km2 {
  margin-left: 5px;
}
.map-p {
  margin: 0;
}

.map-box {
  display: flex;
  // width: 670px;
  // height: 300px;
  // margin-top: 20px;
  opacity: 1;
  border-radius: 0px;
  position: relative;
}
.map-img {
  float: left;
  width: 330px;
  // height: 300px;
  margin-left: 30px;
  margin-right: 20px;
}
.mapright {
  display: flex;
  flex-direction: column;
}

.position,
.position1,
.position2,
.position3 {
  float: left;
  width: 18px;
  height: 20px;
}
.jie1,
.jie2,
.jie3,
.jie4 {
  position: relative;
}
.position {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position1 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position2 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.position3 {
  position: absolute;
  top: 4px;
  left: -5px;
}
.map-p {
  margin-bottom: 8px;
}
.main-m,
.main-m1 {
  width: 105px;
  height: 22px;
  text-align: center;
  margin-left: 20px;
  margin-bottom: 8px;
  line-height: 22px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
  background: #e9ebf2;
  opacity: 1;
}
.main-mn {
  width: 129px;
  height: 22px;
  font-size: 12px;
  text-align: center;
  margin-left: 20px;
  line-height: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2c68ff;
  opacity: 1;
  background: #e8f3fe;
  opacity: 1;
}

.btn {
  float: left;
  width: 600px;
  height: 80px;
  opacity: 1;
  margin-left: 55px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 20px;
  cursor: pointer;
}
.cancel,
.cancel2 {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-right: 20px;
  border-radius: 2px;
  cursor: pointer;
}
.cancel2 {
  width: 100px;
}
.xiangpai {
  width: 80px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 2px;
  border: 0;
}
.cancel:hover {
  color: #2c68ff;
  // background-color: #2c68ff;
}

.car-photo {
  width: 100%;
  height: 128px;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  // background-color: fuchsia;
}
.car-p1 {
  float: left;
  margin-right: 30px;
  width: 160px;
  height: 128px;
  background-color: #f5faff;
  margin-top: 10px;
}
.car-img {
  width: 114px;
  height: 69px;
  margin-left: 23px;
  margin-top: 16px;
}
.car-span {
  float: left;
  width: 130px;
  text-align: center;
  margin-left: 15px;
  margin-top: 10px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
.owner-photo {
  width: 100%;
  // height: 200px;
  float: left;
  clear: both;
}

.rizhi {
  margin-left: 30px;
  margin-bottom: 10px;
  display: flex;
}
.rizhileft {
  width: 450px;
  background-color: pink;
  display: flex;
}
.rizhiTit,
.rizhiMain {
  font-size: 14px;
  font-weight: 400;
  color: #2a3346;

  box-sizing: border-box;
  display: inline-block;
}
.rizhiTit {
  width: 180px;
}
.rizhiTxt {
  width: 200px;
  font-size: 14px;
  color: #2c68ff;
  margin-left: 15px;
}
.rizhiMain {
  width: 100px;
  display: inline-block;
  margin-left: 10px;
  box-sizing: border-box;
}

.car-p1 /deep/ .el-image {
  width: 130px;
  height: 90px;
  margin-left: 15px;
  margin-top: 10px;
}

// audio{
//   width: 637px;
//   margin-left: 30px;
//   margin-top: 20px;
// }
audio {
  width: 250px;
  height: 20px;
  vertical-align: middle;
  float: right;
}
.rikong {
  width: 250px;
  height: 20px;
  vertical-align: middle;
  float: right;
}
</style>
<style lang="less">
.xx {
  width: auto;
  height: auto;
  background: #fff;
}
.xxx {
  padding: 5px 10px;
  line-height: 20px;
  font-size: 12px;
}

.damapbox {
  display: flex;
  flex-direction: column;
}
.ditubox {
  width: 800px !important;
  height: 500px !important;
}
.damap {
  width: 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 12px;
  border-radius: 4px;
  margin-left: 12px;
  cursor: pointer;
}
.mapMain {
  margin-top: 10px;
  padding-left: 30px;
  box-sizing: border-box;
}
.danmain {
  width: 300px;
  display: inline-block;
  margin-right: 30px;
  vertical-align: top;
}
.shijian {
  width: 650px;
  margin-left: 30px;
  background: #f8fafc;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
.makerlabel {
  // text-align: center;
  position: relative;
}
.mapaddress {
  // display: inline-block;
  position: absolute;
  left: -130px;
  width: 300px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.36);
  color: #fff;
}
.rileft {
  display: flex;
  // min-width: 450px;
  box-sizing: border-box;
}

.splitimg {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.splitdanum {
  font-size: 14px;
  color: #2c68ff;
  margin-right: 40px;
  cursor: pointer;
}
.splitnum {
  display: flex;
  align-items: center;
  margin: 18px 0px;
  margin-left: 30px;
}
.splitnumbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
