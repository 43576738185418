<template>
  <div class="searchBox">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="searchTit">电子协议-修改</p>
      <!-- 合作单位 -->
      <div class="yuitem">
        <label class="yulabel" for="">合作单位</label>
        <span class="yuinput">{{ xieyieditTit }}</span>
      </div>
      <div class="yuitem">
        <label class="yulabel" for="">签署日期</label>
        <el-date-picker
                class="yuTime"
                v-model="startTime"
                type="date"
                placeholder="请选择时间"
                size="default"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
      </div>

      <div class="yuitem">
        <label class="yulabel" for="">到期日期</label>
        <el-date-picker
                class="yuTime"
                v-model="endTime"
                type="date"
                placeholder="请选择时间"
                size="default"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
      </div>

      <div class="yuitem">
        <label class="yulabel" for="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备注</label>
          <el-input
              class="text"
              v-model="remark"
              type="textarea"
              placeholder="请输入备注信息"
            />
      </div>


      <div class="yuitem">
        <label class="yulabel" for="">上传协议</label>
        <button :class="startTime != '' && endTime != ''?'yubtn2':'yubtn3'" @click="upp">上传协议，只支持PDF</button>
        <a :href="this.pdf" target="_blank" v-if="pdf != ''" style="font-size:14px;margin-left:15px; color:blue;">点击预览</a>
        <input style="display:none" type="file" @change="change" id="uploadpdf" accept=".pdf"/>
      </div>

      <!-- 按钮 -->
      <div class="btn">
        <button :class="pdf != '' && startTime != '' && endTime != ''?'set':'set1'" @click="bao">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>

  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import * as api from "../api/index";
import search from "./search.vue";
import Yusearch from "./yusearch.vue";
import { fa } from "element-plus/lib/locale";
import axios from "axios";
export default {
  components: { search, Yusearch },
  props: ["xieyieditTit", "xieyieditCid","xieyieditId"],
  data() {
    return {
      yuweizhi: false,
      yuisaddrs: "",
      foundaddryu1: "",
      foundlnglatyu1: "",
      yushes: this.isyu,
      xinxiList: [],
      price: "",
      price2: "",
      prive3: "",

      hezuo: this.yusheTitP,
      address: "",
      destination: "",
      addressLnglat: "",
      destinationLnglat: "",
      oldaddressLnglat: "",
      olddestinationLnglat: "",
      selectIndex: "",
      isShow: false,
      words: [],
      isShow2: false,
      words2: [],
      ycid: "",
      distance: "",
      id: "",
      flag: "",

      yinbtn: false,
      isreadonly: this.isreadonly,
      startTime:'',
      endTime:'',
      pdf:'',
      remark:'',

    };
  },

  created() {
    this.getdetail();
  },

  methods: {
    async getdetail()
    {
      let res = await api.GetXieyiDetail(this.xieyieditCid,this.xieyieditId);
      console.log(res);
      this.startTime = res.data.starttime;
      this.endTime = res.data.endtime;
      this.pdf = res.data.pdf;
      this.remark = res.data.remark;
    },
    close() {
      this.startTime = '';
      this.endTime = '';
      this.pdf = '';
      this.remark = '';
      this.$emit("closexyy2");
    },
    async bao(){
      if(this.pdf == '' )
      {
        this.$message.warning("请先上传pdf,再进行保存");
        return false;
      }

      // console.log(this.startTime);
      // console.log(this.endTime);
      

      // return false;

      let res = await api.editXieyi(this.xieyieditId,this.xieyieditCid,this.startTime,this.endTime,this.pdf,this.remark);

      if(res.code == 1)
      {
        this.close();
      }
    },
    upp()
    {
      if(this.startTime == '' || this.endTime == '')
      {
        this.$message.warning("请先完整上面的日期，再进行pdf上传");
      }else{
        $('#uploadpdf').click();     
      }
  
    },
    async change(e)
    {
        console.log(e.target.files[0]);
        let file = e.target.files[0];
        let _this = this;

        let formData = new FormData();
        formData.append("file", file);
        formData.append("cid", this.xieyieditCid);
        formData.append("st", parseInt(new Date(Date.parse(this.startTime)).getTime() / 1000));
        formData.append("et", parseInt(new Date(Date.parse(this.endTime)).getTime() / 1000));

        axios
          .post(
            "https://www-admin.nituowola.com/upload/index2",
            formData
          )
          .then((res) => {
            if (res.data.code == 0) {
                console.log(res);
                _this.pdf = res.data.data.src;
            } else {
              _this.$message.warning("上传PDF失败");
            }
          });
    }

  },

  watch: {    
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 640px;
  background-color: skyblue;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}
.searchMain {
  width: 640px;
  height: 920px;
  background-color: #fff;
  padding-top: 40px;
  padding-left: 30px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 65px;
  left: -40px;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  font-weight: bold;
  box-sizing: border-box;
}
.yuitem {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.yulabel {
  margin-right: 20px;
  display: inline-block;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 800;
  //   line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
.yuinput {
  width: 440px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  padding-left: 12px;
  font-weight: 800;
  color:#888888;
}
.yuinput::-webkit-input-placeholder {
  color: #c0c4cc;
}
.mapImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
}
.add {
  float: left;
  width: 30px;
  height: 32px;
  margin-left: 50px;
  cursor: pointer;
}
// .bite {
//   width: 380px;
//   margin-right: 10px;
// }
.yuxinBox {
  width: 580px;
  max-height: 400px;
  margin-top: 24px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
}
.yuxinBox::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
.foundyuxinBox {
  max-height: 800px;
}
.yutable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.yutable td {
  text-align: center;
}
.yubtn {
  width: 50px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 20px;
  border: 0;
  cursor: pointer;
}
.yubtn1 {
  width: 60px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 440px;
  border: 0;
  cursor: pointer;
}

.yubtn2 {
  width: 220px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  border:0;
  border-radius:5px;
  cursor: pointer;

}

.yubtn3 {
  width: 220px;
  height: 32px;
  background-color: #888888;
  color: #fff;
  border:0;
  border-radius:5px;
  cursor: pointer;

}
.xiubtn {
  width: 50px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.btn {
  margin-top: 46px;
  // padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}

.set1 {
  width: 80px;
  height: 32px;
  background: #888888;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.yutit {
  height: 32px;
  background-color: #f5f5f5;
}
// .yutxt {
//   height: 50px;
// }
.gai,
.shan {
  width: 32px;
  height: 20px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
.gai {
  background-color: #2c68ff;
  color: #fff;
}
.shan {
  margin-left: 10px;
}
.renwu,
.mudi {
  width: 160px;
}
.yikou {
  width: 125px;
}
.operation {
  width: 130px;
}
.select-panel {
  // height: 100px;
  position: absolute;
  top: 35px;
  margin-left: 80px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
.input-norm1 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  border-right: 0;
}
.ding {
  position: relative;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  height: 50px;
  background-color: #ffffff !important;
}
.bg_color2 {
  height: 50px;
  background-color: #fafafa !important;
}
.jishi {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 12px;
}
.jishiint {
  width: 80px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
  // text-align: center;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  outline: none;
}
.yuan {
  width: 32px;
  height: 30px;
  box-sizing: border-box;
  background-color: #f7f9fb;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.priceTit,
.juli {
  font-size: 14px;
}

.yuitem .el-textarea{
  width:220px !important;
}
</style>
