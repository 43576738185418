<template>
  <div>
    <!-- 添加合作单位 -->
    <div class="box" v-show="sites">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
      <div class="box-main">
        <div class="main-nav">
          <h1>查看合作单位</h1>
        </div>
        <!-- 合作单位 -->
        <div class="append-name">
          <div class="xing">*</div>
          <label class="label1" for="">合作单位</label>
          <input
            type="text"
            class="biginput"
            v-model="name"
            placeholder="请输入合作单位名称"
            disabled
            v-if="name == '本公司'"
          />
          <input
            type="text"
            class="biginput"
            v-model="name"
            placeholder="请输入合作单位名称"
            v-if="name != '本公司'"
          />
        </div>
        <!-- 公司简称 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">公司简称</label>
          <input type="text" class="biginput" v-model="short" placeholder="请输入简称" />
        </div>
        <!-- 公司类型 -->
        <div class="append-name" v-if="companytype != 1">
          <div class="xing"></div>
          <label class="label1" for="">公司类型</label>
          <el-select
            v-model="this.companytype"
            placeholder="请选择公司类型"
            @change="changecarstyle"
            style="width: 280px"
          >
            <el-option
              v-for="item in typelist"
              :key="item.id"
              :label="item.type_name"
              :value="item.id"
            >
              {{ item.type_name }}
            </el-option>
          </el-select>
        </div>
        <!-- 车商品牌 -->
        <div class="append-name" v-if="showbrand">
          <div class="xing"></div>
          <label class="label1" for="">车商品牌</label>
          <input type="text" class="biginput" v-model="brand" placeholder="请输入车商品牌" />
        </div>
        <!-- 联系人 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">联系人&#12288;</label>
          <input type="text"  class="biginput" v-model="contact" placeholder="请输入联系人" />
        </div>
        <!-- 联系电话 -->
        <div class="append-name">
          <div class="xing">*</div>
          <label class="label1" for="">联系电话</label>
          <input type="text"  class="biginput" v-model="tel" placeholder="请输入联系电话" />
        </div>
        <!-- 联系地址 -->
        <div class="append-name">
          <div class="xing1">*</div>
          <label class="label1"  for="">联系地址</label>
          <input
            v-model="addressC"
            class="accident-input"
            style="padding-left:7px;"
            @blur="blurC"
            type="text"
            placeholder="请输入地址并在下拉框选中"
          />
          <div class="select-panel" v-show="isShowC">
            <ul>
              <li
                class="der"
                v-for="(item, index) in wordsC"
                :key="index"
                @click="mapC(index)"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>
        <input class="input-norm1" type="hidden" v-model="addressLnglatC" />
        <!-- 备注 -->
        <div class="append-name">
          <div class="xing"></div>
          <label class="wenbentit" for="">备注&#12288;&#12288;</label>
          <el-input
            class="remark-txt"
            v-model="textarea1"
            autosize
            type="textarea"
            placeholder="请输入备注信息"
          />
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <label class="label1" for="">业务归属</label>
          <el-select
            v-model="this.saleman"
            placeholder="请选择业务员"
            @change="changesaleman"
            style="width: 280px"
          >
            <el-option
              v-for="item in saleslist"
              :key="item.uid"
              :label="item.nickname"
              :value="item.uid"
            >
              {{ item.nickname }}
            </el-option>
          </el-select>
        </div>

        <div class="append-name">
          <div class="xing"></div>
          <label class="wenbentit" for="">开票信息</label>
          <el-input
            class="remark-txt"
            v-model="piao"
            autosize
            type="textarea"
            placeholder="请输入开票信息"
          />
        </div>

        <!-- <div class="append-name">
          <div class="xing"> </div>
          <label class="label1" for="">开票信息</label>
           <el-input
            class="remark-txt"
            type="textarea"
            placeholder="请输入开票信息"
            v-model="piao"
          />
        </div> -->
        <div class="addphoto">
          <div class="xing"></div>
          <label class="addlabel" for="">增值服务</label>
          <div class="shangchuan">
            <div class="tuBox">
              <div class="beiTu">
                <img class="tuImg" :src="img88" alt="" />
                <input class="tu" type="file" @change="change" data-index="1" />
              </div>
            </div>
            <div
              style="float: left; margin-top: 60px"
              v-if="img88 != ''"
              @click="delimg88"
            >
              删除示例照
            </div>
          </div>
        </div>
        
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="订单类型">
              <el-radio v-model="js"    :label="1" @click.prevent="cc(js)">即时单</el-radio>
              <el-radio v-model="yy"    :label="1" @click.prevent="dd(yy)" >预约单</el-radio>
              <el-radio v-model="sl"    :label="1" @click.prevent="ee(sl)" >顺路单</el-radio>
            </el-form-item>
          </el-form>
        </div>

        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="需要回访">
              <el-radio v-model="huixuan" label="1">是</el-radio>
              <el-radio v-model="huixuan" label="0">否</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="联系车主">
              <el-radio v-model="contactxuan" label="1">必须</el-radio>
              <el-radio v-model="contactxuan" label="0">非必须</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="车型价格">
              <el-radio v-model="carprice" label="1">启用</el-radio>
              <el-radio v-model="carprice" label="0">停用</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="区域价格">
              <el-radio v-model="areaprice" label="1">启用</el-radio>
              <el-radio v-model="areaprice" label="0">停用</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="结算方式">
              <el-radio v-model="plancal" label="1">规划公里</el-radio>
              <el-radio v-model="plancal" label="0">实际公里</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="默认拖车">
              <el-radio v-model="isrescue" label="1">道路救援</el-radio>
              <el-radio v-model="isrescue" label="2">整车运输</el-radio>
            </el-form-item>
          </el-form>
        </div>
        <div class="append-name" v-if="companytype == 19">
          <div class="xing"></div>
          <label class="label1" for="">归属集团</label>
          <el-select
            v-model="groupid"
            placeholder="请选择集团主公司"
            @change="changegropu"
            style="width: 280px"
          >
            <el-option
              filterable
              v-for="item in grouplist"
              :key="item.cid"
              :label="item.name"
              :value="item.cid"
            >
              {{ item.name }}
            </el-option>
          </el-select>
        </div>
        <div class="append-name" v-if="showAi">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="智能识别">
              <el-radio v-model="ocrcarnumber" :label="1" @click.prevent="aa(ocrcarnumber)">车牌识别</el-radio>
              <el-radio v-model="ocrcarvin"    :label="1" @click.prevent="bb(ocrcarvin)" >车架识别</el-radio>
            </el-form-item>
          </el-form>
        </div>

        <div class="append-name" v-if="showAutomation">
          <div class="xing"></div>
          <el-form ref="form">
            <el-form-item label="自动调派">
              <el-radio v-model="automationtype" label="0">关闭</el-radio>
              <el-radio v-model="automationtype" label="1">无差推送</el-radio>
              <el-radio v-model="automationtype" label="2">公里递推</el-radio>
            </el-form-item>
          </el-form>
        </div>

        <div class="append-name" v-if="automationtype >= 1">
          <div class="xing"></div>
          <div class="autostyle" v-if="automationtype == 1"><input type="text" class="autoinput" v-model="auto_y"/>公里内推送</div>
          <div class="autostyle" v-if="automationtype == 2"><input type="text" class="autoinput" v-model="auto_x"/>公里起，以每<input type="text"  class="autoinput" v-model="auto_z" />秒/公里向外递推;最远至<input type="text"  class="autoinput" v-model="auto_y" />公里</div>
          <div class="autostyle1">&nbsp;&nbsp;生效时间：<input type="number" class="autoinput1" v-model="auto_start" @input="as"/>时 - <input type="number" class="autoinput1" v-model="auto_end" @input="ae"/>时</div>
        </div>

     

        <div class="btn" v-if="!getlook">
          <el-button class="set" :plain="true" @click="save" >保存</el-button>
          <button @click="close" class="cancel">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/join";
import { reactive, ref } from "vue";
import { joinAdd } from "../api/join";
import * as api1 from "../api/index";
import { compress } from "../api/pic";
import axios from "axios";
const textarea1 = ref("");

const formInline = reactive({
  user: "",
  region: "",
});

export default {
  props: ["isJoin", "cid"],
  data() {
    return {
      //options,
      formInline,
      textarea1,
      sites: this.isJoin,
      isShowC: false,
      addressC: "",
      addressLnglatC: "",
      oldaddress: "",
      wordsC: [],
      newvalsC: "",
      contact: "",
      tel: "",
      name: "",
      short: "",
      companytype: "",
      typelist: [],
      brand: "",
      showbrand: false,
      piao: "",
      saleman: "",
      saleslist: [],
      img88: "",

      huixuan: "0",
      contactxuan: "0",
      carprice:"0",
      areaprice:"0",
      plancal:'0',
      groupid:'',
      grouplist:[],
      ocrcarnumber:0,
      ocrcarvin:0,
      showAi:false,
      showAutomation:false,
      automationtype:'0',
      auto_x:'',
      auto_y:'',
      auto_z:'',
      auto_start:'',
      auto_end:'',
      isrescue:'2',
      js:1,
      yy:1,
      sl:1,
    };
  },
  methods: {
    async close() {
      this.sites = false;
      let res = await api.jionDetail(this.cid);
      console.log(res);
      this.name = res.data.data.name;
      this.tel = res.data.data.tel;
      this.contact = res.data.data.contact;
      this.addressC = res.data.data.address;
      this.oldaddress = res.data.data.address;
      this.addressLnglatC = res.data.data.addressLnglat;
      this.short = res.data.data.short;
      this.companytype = res.data.data.companytype;
      this.typelist = res.data.data.typelist;
      this.brand = res.data.data.brand;
      this.piao = res.data.data.piao_content;
      this.saleslist = res.data.data.saleslist;
      this.textarea1 = res.data.data.remark;
      this.saleman = res.data.data.saleman;
      this.img88 = res.data.data.img88;
      this.huixuan = res.data.data.is_need_return;
      this.contactxuan = res.data.data.is_need_tel;
      this.carprice = res.data.data.is_carprice;
      this.areaprice = res.data.data.is_areaprice;
      this.grouplist = res.data.data.grouplist;
      this.groupid = res.data.data.group_id;
      this.ocrcarnumber = res.data.data.ocrcarnumber;
      this.ocrcarvin = res.data.data.ocrcarvin;
      this.showAi = res.data.data.isShowAi;
      this.showAutomation = res.data.data.isShowAuto;
      this.automationtype = res.data.data.automation.toString();
      this.auto_x =  res.data.data.auto_x;
      this.auto_y =  res.data.data.auto_y;
      this.auto_z =  res.data.data.auto_z;
      this.auto_start = res.data.data.auto_start;
      this.auto_end = res.data.data.auto_end;
      this.isrescue = res.data.data.isrescue;
      if (
        this.companytype == 4 ||
        this.companytype == 8 ||
        this.companytype == 9
      ) {
        this.showbrand = true;
      } else {
        this.showbrand = false;
      }
    },
    as()
    {
        if(this.auto_start < 0)
        {
          this.auto_start = 0;
        }else if(this.auto_start > 23)
        {
          this.auto_start = 23;
        }   
    },
    ae()
    {
        if(this.auto_end < 0)
        {
          this.auto_end = 0;
        }else if(this.auto_end > 23)
        {
          this.auto_end = 23;
        }
    },
    aa(value)
    {
      this.ocrcarnumber = value == 1?0:1;
    },
    bb(value)
    {
      this.ocrcarvin = value == 1?0:1;
    },
    cc(value){
      this.js = value == 1?0:1;
    },
    dd(value){
      this.yy = value == 1?0:1;
    },
    ee(value){
      this.sl = value == 1?0:1;
    },
    async change(e) {
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-auth-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.img88 =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;
              console.log(_this.img88);
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },
    async save() {
      if (this.name == "") {
        this.$message.warning("请输入合作商名称");
        return false;
      }
      if (this.tel == "") {
        this.$message.warning("请输入联系电话");
        return false;
      }

      console.log(this.auto_start);
      console.log(this.auto_end);

      if(this.automationtype == 0)
      {
        this.auto_x = 0;
        this.auto_y = 0;
        this.auto_z = 0;
        this.auto_end = null;
        this.auto_start = null;
      }else if(this.automationtype == 1)
      {
        this.auto_x = 0;
        this.auto_z = 0;
        if(this.auto_y == '' && this.auto_y == 0)
        {
          this.$message.warning("请填写正确的推送距离");
          return false;
        }
        if((this.auto_start == '' && this.auto_start != 0)|| (this.auto_end == '' && this.auto_end != 0))
        {
          this.$message.warning("请选择正确的生效时间段");
          return false;
        }
      }else if(this.automationtype == 2)
      {
        if(this.auto_x == '' && this.auto_x == 0)
        {
          this.$message.warning("请填写正确的推送起始距离");
          return false;
        }
        if(this.auto_y == '' && this.auto_y == 0)
        {
          this.$message.warning("请填写正确的推送最大距离");
          return false;
        }
        if(this.auto_z == '' && this.auto_z == 0)
        {
          this.$message.warning("请填写正确的递推速度");
          return false;
        }
        if((this.auto_start == '' && this.auto_start != 0)|| (this.auto_end == '' && this.auto_end != 0))
        {
          this.$message.warning("请选择正确的生效时间段");
          return false;
        }
      }

      if(this.carprice == 1 && this.areaprice == 1)
      {
        this.$message.warning("车型价格与区域价格只能存在一个");
        return false;
      }


      let res = await api.joinupdate(
        this.cid,
        this.name,
        this.addressC,
        this.tel,
        this.contact,
        this.addressLnglatC,
        this.textarea1,
        this.short,
        this.companytype,
        this.brand,
        this.piao,
        this.saleman,
        this.img88,
        this.huixuan,
        this.contactxuan,
        this.carprice,
        this.groupid,
        this.plancal,
        this.ocrcarnumber,
        this.ocrcarvin,
        this.automationtype,
        this.auto_x,
        this.auto_y,
        this.auto_z,
        this.auto_start,
        this.auto_end,
        this.isrescue,
        this.js,
        this.yy,
        this.sl,
        this.areaprice,
      );
      if (res.code == 1) {
        this.$message.success(res.message);
        this.$emit("datass", new Date().getTime());
        this.sites = false;
      } else {
        this.$message.warning(res.message);
      }
    },
    compressAvatard(file, kb, callback) {
      let _this = this;
      let raw = file.raw ? file.raw : file;
      compress(raw, function (val) {
        // val是Blob类型，转换为file类型
        let newfile = new window.File([val], file.name, { type: raw.type });
        newfile.uid = file.uid;
        // 压缩率过低则停止压缩, 防止死循环
        if (newfile.size / 1024 > kb && newfile.size / file.size < 0.9) {
          console.log("图片过大:" + newfile.size + ",已压缩");
          _this.compressAvatard(newfile, kb, callback);
        } else {
          callback(newfile);
          console.log("压缩后大小:" + newfile.size);
        }
      });
    },
    blurC() {
      setTimeout(() => {
        this.isShowC = false;
      }, 200);
    },
    async searchC(newval) {
      this.newvalsC = newval;
      this.addressLnglatC = "";
      let res = await api1.workMap(this.newvalsC);
      this.wordsC = res.data;
      if (this.wordsC.length > 0) {
        this.isShowC = true;
      } else {
        this.isShowC = false;
      }
    },
    delimg88() {
      this.img88 = "";
    },
    mapC(query) {
      this.addressC = this.wordsC[query].value;
      this.oldaddress = this.wordsC[query].value;
      this.addressLnglatC = this.wordsC[query].location;
      this.isShowC = false;
    },
    changecarstyle() {
      if (
        this.companytype == 4 ||
        this.companytype == 8 ||
        this.companytype == 9
      ) {
        this.showbrand = true;
      } else {
        this.showbrand = false;
      }
    },
    changesaleman() {
      console.log(this.saleman);
    },
  },
  watch: {
    isJoin(newval) {
      console.log("---", newval);
      this.sites = newval;
    },
    addressC(newval) {
      console.log("------", newval);
      if (newval == "") {
        this.isShowC = false;
        return false;
      }

      if (this.oldaddress != newval) {
        this.addressLnglatC = "";
      }
      if (this.addressLnglatC == "") {
        this.searchC(newval);
      }
    },
    async cid(newval) {
      let res = await api.jionDetail(newval);
      //console.log(res);
      this.name = res.data.data.name;
      this.tel = res.data.data.tel;
      this.contact = res.data.data.contact;
      this.addressC = res.data.data.address;
      this.oldaddress = res.data.data.address;
      this.addressLnglatC = res.data.data.addressLnglat;
      this.short = res.data.data.short;
      this.companytype = res.data.data.companytype;
      this.typelist = res.data.data.typelist;
      this.textarea1 = res.data.data.remark;
      this.brand = res.data.data.brand;
      this.piao = res.data.data.piao_content;
      this.saleslist = res.data.data.saleslist;
      this.saleman = res.data.data.saleman;
      this.img88 = res.data.data.img88;
      this.huixuan = res.data.data.is_need_return;
      this.contactxuan = res.data.data.is_need_tel;
      this.carprice = res.data.data.is_carprice;
      this.areaprice = res.data.data.is_areaprice;
      this.grouplist = res.data.data.grouplist;
      this.groupid = res.data.data.group_id;
      this.plancal = res.data.data.plancal;
      this.ocrcarnumber = res.data.data.ocrcarnumber;
      this.ocrcarvin = res.data.data.ocrcarvin;
      this.showAi = res.data.data.isShowAi;
      this.showAutomation = res.data.data.isShowAuto;
      this.automationtype = res.data.data.automation.toString();
      this.auto_x =  res.data.data.auto_x;
      this.auto_y =  res.data.data.auto_y;
      this.auto_z =  res.data.data.auto_z;
      this.auto_start = res.data.data.auto_start;
      this.auto_end = res.data.data.auto_end;
      this.isrescue = res.data.data.isrescue;
      this.js = res.data.data.js;
      this.yy = res.data.data.yy;
      this.sl = res.data.data.sl;
      if (
        this.companytype == 4 ||
        this.companytype == 8 ||
        this.companytype == 9
      ) {
        this.showbrand = true;
      } else {
        this.showbrand = false;
      }

      //console.log(this.typelist);
    },
  },
  computed: {
    getlook() {
      return this.$store.state.lookonly;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 640px;
  height: 920px;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 22;
}
.select-panel {
  // height: 100px;
  position: absolute;
  margin-left: 77px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  margin-top: 70px;
  margin-left: 0px;
}
.box-main {
  width: 600px;
  height: 920px;
  //   background: orange;
  margin-left: 40px;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  position: relative;
  background-color: #fff;
  overflow-y: scroll;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 600px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  width: 170px;
  height: 26px;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 117px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  cursor: pointer;
  vertical-align: top;
}

.remark-txt {
  width: 350px;
  // height: 40px;
  // margin-left: 45px;
}
.remark-label {
  line-height: 40px;
  font-size: 14px;
  margin-left: 8px;
}
.site {
  margin-left: 33px;
  margin-top: 12px;
  font-size: 14px;
}
.site-label {
  margin-right: 20px;
}
.site-input {
  margin-left: 115px;
  margin-top: 12px;
}
.append-name,
.append-short,
.append-type,
.append-phone,
.contact,
.site,
.remark {
  width: 500px;
  // height: 40px;
  opacity: 1;
}
.append-name {
  margin-top: 10px;
  margin-left: 30px;
}
.append-phone {
  margin-top: 12px;
  margin-left: 40px;
}
.append-short {
  margin-top: 12px;
  margin-left: 33px;
}
.append-type {
  margin-top: 12px;
  margin-left: 33px;
}
.box-main /deep/ .el-select .el-input {
  width: 350px;
  height: 32px;
}

.contact {
  margin-top: 12px;
  margin-left: 33px;
}
.remark {
  margin-top: 12px;
  margin-left: 33px;
}
.label1,
.label2 {
  // width: 56px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  display: inline-block;
}
.label1 {
  margin-right: 20px;
}
.label2 {
  margin-right: 34px;
}
.wenbentit {
  height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #2a3346;
  opacity: 1;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}

input {
  width: 350px;
  height: 32px;
  //padding-left: 7px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  outline: none;
}
input:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
input::-webkit-input-placeholder {
  color: #c0c4cc;
}
.xing,
.xing1 {
  float: left;
  width: 6px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #ff505a;
  opacity: 1;
}
.xing {
  margin-top: 15px;
}
.xing1 {
  margin-top: 15px;
}

.addphoto {
  width: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  margin-top: 20px;
  display: flex;
}
.addlabel {
  width: 80px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  display: inline-block;
  // margin-right: 20px;
}
.shangchuan {
  box-sizing: border-box;
}

.tuBox {
  width: 153px;
  height: 100px;
  float: left;
  position: relative;
  margin-right: 18px;
}
.autostyle{
  margin-left:70px;
}
.autostyle1{
  margin-left:70px;
  margin-top:10px;
}

.autoinput{
  width:30px;
  text-align: center;
  margin: 0px 5px;
}

.autoinput1{
  width:50px;
  text-align: center;
  margin: 0px 5px;
}
.biginput{
  padding-left: 7px;
}

.tu {
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.tuImg {
  width: 153px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  // padding: 2px;
  // border: 1px solid #e0e0e0;
  //box-sizing: border-box;
}
img[src=""],
img:not([src]) {
  opacity: 0;
}
.beiTu {
  width: 153px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/cd/tishi.png");
  background-size: 100% 100%;
}

.append-name /deep/ .el-form-item__label {
  margin-right: 20px;
  padding: 0;
  box-sizing: border-box;
}
</style>
