<template>
  <div class="depositBox" v-show="ti">
    <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
    <h2 class="depositTit">提现</h2>
    <div class="depofitMain">
      <div class="money">
        <label class="moneyTit" for="">可提现金额:</label>
        <i class="num">¥{{ account }}</i>
        <div
          class="right"
          @mouseenter="mouseenterFun"
          @mouseleave="mouseleaveFun"
        >
          <img class="icon" src="../assets/img/cd/ti.png" alt="" />
          <span class="depositTi">提现须知</span>
        </div>

        <div class="notice" v-show="yin">
          <h2 class="noticeTi">提现须知:</h2>
          <p class="noticeMain">暂无内容</p>
          <!-- <p class="noticeMain">1.如：按税务要求，无法开具增值税专用发票的以其他方式支付须交纳手续费等</p>
            <p class="noticeMain">2.吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦</p>
            <p class="noticeMain">3.吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦</p>
            <p class="noticeMain">4.吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦吧啦</p> -->
        </div>
      </div>
      <div class="keMoney">
        <i class="xing">*</i>
        <label for="">提现金额:</label>
        <input
          type="text"
          v-model="tixianmoney"
          placeholder="请输入提现金额"
          @input="formatValue(tixianmoney)"
        />
        <button class="btn" @click="all">全部提现</button>
      </div>
      <div class="house">
        <label for="">银行账号:</label>
        <input
          type="text"
          v-model="bankcard"
          placeholder="1287659076341523"
          disabled
        />
      </div>
      <div class="account">
        <label for="">开户行:</label>
        <input type="text" v-model="bankname" placeholder="建设银行" disabled />
      </div>
      <div class="firm">
        <label for="">公司名称:</label>
        <input
          type="text"
          v-model="companyname"
          placeholder="摩西摩西有限公司"
          disabled
        />
      </div>

      <div class="photo-box">
        <label class="label5" for="">发票照片</label>
        <div class="upBox">
          <div class="tuBox">
            <div class="beiTu">
              <img class="tuImg" :src="imgSrc[0].imgSrc" alt="" />
            </div>
            <input class="tu" type="file" @change="change" data-index="0"/>
            <span class="photo-span">开票信息</span>
          </div>
        </div>
      </div>

      <div class="btn1">
        <button class="set" @click="tixian">确定</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as index from "../api/index";
import { compress } from "../api/pic";
import axios from "axios";
export default {
  props: ["isTi", "tiprice"],
  data() {
    return {
      ti: this.isTi,
      yin: false,
      account: 0,
      bankcard: "",
      bankname: "",
      companyname: "",
      tixianmoney: "",
      imgSrc: [{ imgSrc: "" }],
    };
  },
  computed: {
    // tixianmoney() {
    //   return this.tiprice;
    // },
  },
  methods: {
    async change(e) {
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      let index = Number(e.target.dataset.index);
      console.log(file);
      let _this = this;
      let formData = new FormData();
      // 压缩后上传
      this.compressAvatard(file, 1024 * 3, function (res) {
        formData.append("pic", res);
        axios
          .post(
            "https://dispatch-test.nituowola.com/site/upload-auth-pic",
            formData
          )
          .then((res) => {
            if (res.data.code == 1) {
              _this.imgSrc[index].imgSrc =
                "https://ntwl-pic-sh.oss-cn-shanghai.aliyuncs.com/" +
                res.data.pic;
            } else {
              _this.$message.warning("图片上传失败");
            }
          });
      });
    },
    compressAvatard(file, kb, callback) {
      let _this = this;
      let raw = file.raw ? file.raw : file;
      compress(raw, function (val) {
        // val是Blob类型，转换为file类型
        let newfile = new window.File([val], file.name, { type: raw.type });
        newfile.uid = file.uid;
        // 压缩率过低则停止压缩, 防止死循环
        if (newfile.size / 1024 > kb && newfile.size / file.size < 0.9) {
          console.log("图片过大:" + newfile.size + ",已压缩");
          _this.compressAvatard(newfile, kb, callback);
        } else {
          callback(newfile);
          console.log("压缩后大小:" + newfile.size);
        }
      });
    },

    close() {
      this.ti = false;
    },
    mouseenterFun() {
      this.yin = true;
    },
    mouseleaveFun() {
      this.yin = false;
    },
    all() {
      this.tixianmoney = this.account;
    },
    formatValue(val) {
      //console.log(val);
      val = val.toString();
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.value = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      //console.log(val);
      this.tixianmoney = val;
    },
    async getinfo() {
      let res = await index.getTixianAccount();
      this.account = res.data.account.account;
      this.bankcard = res.data.auth.bank_num;
      this.bankname = res.data.auth.open_name;
      this.companyname = res.data.auth.sp_name;
    },
    async tixian() {
      console.log(this.tixianmoney);
      if (this.tixianmoney == "" || this.tixianmoney == 0) {
        this.$message.warning("请输入提现金额");
        return false;
      }

      if (this.tixianmoney > this.account) {
        this.$message.warning("提现金额不能超过账户余额");
        return false;
      }

      if(this.imgSrc[0].imgSrc == '')
      {
        this.$message.warning("请上传对应开票金额的发票照片");
        return false;
      }

      let res = await index.tixianSubmit(this.tixianmoney,this.imgSrc[0].imgSrc);

      if (res.code == 1) {
        this.$message.success("成功");
        this.getinfo();
        this.close();
        this.$emit("tixians", 1234);
      } else {
        this.$message.warning(res.message);
      }
    },
  },
  watch: {
    isTi(newval) {
      this.ti = newval;
      this.getinfo();
    },
    tiprice(newval)
    {
      if(newval != undefined && newval != '')
      {
        this.tixianmoney = newval;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.depositBox {
  width: 500px;
  height: 920px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 11;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.depositTit {
  font-size: 20px;
  height: 80px;
  box-sizing: border-box;
  padding-left: 30px;
  padding-top: 26px;
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 36px;
  left: -40px;
}
.right {
  display: inline-block;
  cursor: pointer;
}
.money {
  margin-left: 30px;
}
.moneyTit {
  font-size: 14px;
}
i {
  font-style: normal;
}
.num {
  width: 240px;
  display: inline-block;
  color: #ff0000;
  font-size: 16px;
  margin-left: 8px;
}
.icon {
  width: 17.5px;
  height: 17.5px;
  margin-left: 13px;
  vertical-align: middle;
}
.depositTi {
  color: #faad14;
  font-size: 14px;
  margin-left: 5px;
}
.xing {
  color: red;
  margin-right: 4px;
}
.keMoney {
  margin-top: 20px;
  margin-left: 33px;
  font-size: 14px;
}
input {
  width: 240px;
  height: 32px;
  border-radius: 4px;
  margin-left: 8px;
  padding-left: 12px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
}
input:hover {
  border: 1px solid #c0c4cc;
}
input[type="text"]:focus {
  outline: none;
  border-color: #409eff;
  background-color: #fff;
}
.btn {
  width: 80px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  color: #2c68ff;
  background: rgba(44, 104, 255, 0.12);
  margin-left: 12px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
}
label {
  font-size: 14px;
}
.house,
.firm {
  margin-left: 44px;
  margin-top: 24px;
}
.account {
  margin-left: 58px;
  margin-top: 24px;
}

.btn1 {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-left: 104px;
  margin-top: 40px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
}

// 提现须知
.notice {
  width: 420px;
  height: 276px;
  background-color: #fff;
  position: absolute;
  top: 112px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.notice::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: 69px;
  top: -18px;
  border: 9px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.noticeTi {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.noticeMain {
  width: 372px;
  font-size: 14px;
  margin-left: 24px;
  margin-top: 12px;
  text-align: center;
}

.photo-box {
  width: 440px;
  display: flex;
  margin-left: 44px;
  margin-top: 24px;
}
.label5 {
  height: 19px;
  font-size: 14px;
  margin-top: 9px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 10px;
}
.tuBox {
  width: 100px;
  height: 160px;
  float: left;
  position: relative;
  margin-right: 18px;
}
.beiTu {
  width: 100px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../assets/img/cd/jia.png");
  background-size: 100% 100%;
}
.tuImg {
  width: 100px;
  height: 126px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}
img[src=""],
img:not([src]) {
  opacity: 0;
}
.tu {
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
}
.photo-span {
  position: absolute;
  top: 130px;
  left: 0;
  width: 100px;
  height: 19px;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
</style>
