<template>
  <div>
    <div class="box" ref="checkbox">
      <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />

      <div class="box-main">
        <div class="main-nav">
          <h1 v-if="detail.isReview == 0">审核 - 初审</h1>
          <h1 v-if="detail.isReview == 1">审核 - 终审</h1>
        </div>

        <div>
          <span class="tiao"></span>
          <div class="ordernum">
            订单信息:{{ detail.订单号 }}
            <span class="take" @click="dingpart">订单详情</span>
          </div>

          <div class="order-main">
            <div class="hezuo">
              <div class="p1">
                <span class="title">合作单位&#12288;</span>
                <span class="title-main">{{ detail.合作单位 }}</span>
              </div>
              <div class="xiangmu">
                <span class="title">服务项目</span>
                <i class="yanse">{{ detail.服务项目 }}</i>
              </div>
            </div>

            <div class="hezuo">
              <div class="p1">
                <span class="title">车主信息&#12288;</span>
                <span class="title-main"
                  >{{ detail.车主姓名 }}/{{ detail.手机号 }}
                  <button
                    @click="call(detail.手机号, detail.订单号)"
                    class="call"
                    v-show="getEmic != ''"
                  ></button
                ></span>
              </div>
              <div class="xiangmu">
                <span class="title">车牌号&#12288;</span>
                <i class="yanse">{{ detail.车牌号 }}</i>
              </div>
            </div>
            <div class="diBox">
              <span class="remTit">车架号&#12288;&#12288;</span>

              <div class="diTxt">
                {{ detail.车架号
                }}<span v-if="detail.ocrvin != ''" style="color: red">
                  - {{ detail.ocrvin }}</span
                >
              </div>
            </div>

            <div class="diBox">
              <span class="remTit">任务点&#12288;&#12288;</span>

              <div class="diTxt">{{ detail.事故地点 }}</div>
            </div>

            <div class="diBox" v-if="detail.目的地">
              <span class="remTit">目的地&#12288;&#12288;</span>

              <div class="diTxt">{{ detail.目的地 }}</div>
            </div>

            <div class="diBox" v-if="detail.arrive">
              <span class="remTit">实际任务点</span>

              <div class="diTxt2">{{ detail.arrive }}</div>
            </div>

            <div class="diBox" v-if="detail.stop">
              <span class="remTit">实际目的地</span>

              <div class="diTxt2">{{ detail.stop }}</div>
            </div>
          </div>
        </div>

        <!-- 相关费用 -->
        <div class="feiyong">
          <div class="owner">
            <span class="tiao1"></span>
            <div class="tishi">
              <span class="feiyongtit">相关费用</span>
              <span class="piancha" v-if="cha > 5">位置偏差过大</span>
            </div>
          </div>

          <div class="feiBox">
            <div class="nor1">
              <span class="norm">收费标准</span>
              <span class="normMain">{{ detail.收费标准 }}</span>
              <span class="tuo" v-if="detail.免托">{{ detail.免托 }}</span>
            </div>
            <div class="nor2" v-if="detail.流转标准">
              <span class="norm">流转标准</span>
              <span class="normMain">{{ detail.流转标准 }}</span>
            </div>
            <div class="nor3" v-if="detail.预估到达距离">
              <span class="norm">到达距离</span>
              <span class="normMain" v-if="detail.预估到达距离"
                >预估：{{ detail.预估到达距离 }}</span
              >
              <span class="normMain" v-if="detail.到达距离"
                >实际：{{ detail.到达距离 }}</span
              >
            </div>
            <div class="nor3" v-if="detail.到达距离2">
              <span class="norm">到达距离</span>
              <span class="normMain" v-if="detail.到达距离2"
                >{{ detail.到达距离2 }}公里</span
              >
            </div>
            <div class="nor4" v-if="detail.目的地">
              <span class="norm">拖车距离</span>
              <span class="normMain" v-if="detail.预估拖车距离"
                >预估：{{ detail.预估拖车距离 }}/{{ detail.planprice }}元</span
              >
              <span class="normMain" v-if="detail.拖车距离"
                >实际：{{ detail.拖车距离 }}</span
              >
              <span class="normMain" v-if="detail.拖车距离2"
                >{{ detail.拖车距离2 }}公里</span
              >
            </div>
            <div class="nor4" v-if="detail.emptydistance">
              <span class="norm">空驶距离</span>
              <span class="normMain"> {{ detail.emptydistance }}公里 </span>
            </div>
            <div class="nor4" v-if="detail.dk_level">
              <span class="norm">地库</span>
              <span class="normMain">{{ detail.dk_level }}</span>
              <span class="normMain"
                >{{ detail.dk_num }}层/{{ detail.dk_price }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.aw_level">
              <span class="norm">辅助轮</span>
              <span class="normMain">{{ detail.aw_level }}</span>
              <span class="normMain"
                >{{ detail.aw_num }}个/{{ detail.aw_price }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.opLevel != ''">
              <span class="norm">外协价格</span>
              <span class="normMain">{{ detail.opLevel }}</span>
            </div>
            <div class="nor4" v-if="detail.opDk != ''">
              <span class="norm">外协地库</span>
              <span class="normMain">{{ detail.opDk }}</span>
              <span class="normMain"
                >{{ detail.dk_num }}层/{{ detail.opDkPrice }}元</span
              >
            </div>
            <div class="nor4" v-if="detail.opAw">
              <span class="norm">外协小轮</span>
              <span class="normMain">{{ detail.opAw }}</span>
              <span class="normMain"
                >{{ detail.aw_num }}个/{{ detail.opAwPrice }}元</span
              >
            </div>
          </div>
        </div>

        <!-- <div class="state">
            <span class="state-span">订单状态</span>
            <el-tooltip placement="top">
              <template #content>订单详情</template>
              <el-button v-if="detail" @click="typebtn"
                >{{ detail.服务项目 }} | {{ detail.服务状态 }}</el-button
              >
            </el-tooltip>
          </div> -->

        <!-- <div class="state">
            <span class="state-span" for="">车牌车架</span>
            <div class="cartit">{{ detail.车牌号 }}</div>
          </div> -->

        <!-- 服务凭证 -->
        <div class="feiyong">
          <div class="owner">
            <span class="tiao1"></span>
            <div class="tishi">
              <span class="feiyongtit">服务凭证</span>
            </div>
          </div>

          <el-checkbox-group v-model="checkList" @change="checkChange">
            <div class="owner" v-if="photo1.length > 0">
              <span class="state-span">现场照&#12288;&#12288;</span>
              <div class="car-photo">
                <div
                  class="car-p1"
                  :class="{ reviewed2: reviewedItems.includes(item.type) }"
                  v-for="(item, index) in photo1"
                  :key="index"
                >
                  <img
                    :src="item.now + '?x-oss-process=style/A_X_S'"
                    @click="showPreview(photo1, index, 1, item.type, item.now)"
                    :class="{ active: currentIndex === index }"
                  />
                  <span class="hint" v-if="hover === index">示例</span>
                  <div
                    class="owBottom"
                    :class="{ reviewed: reviewedItems.includes(item.type) }"
                  >
                    <!-- <el-checkbox-button :key="item.type" :label="item.type" :class="{ reviewed: reviewedItems.includes(item.type) }">
                      {{ item.name }}
                    </el-checkbox-button> -->
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="owner" v-if="photo2.length > 0">
              <span class="state-span">作业照&#12288;&#12288;</span>
              <div class="car-photo">
                <div
                  class="car-p1"
                  :class="{ reviewed2: reviewedItems.includes(item.type) }"
                  v-for="(item, index) in photo2"
                  :key="index"
                >
                  <img
                    :src="item.now + '?x-oss-process=style/A_X_S'"
                    @click="showPreview(photo2, index, 2, item.type, item.now)"
                    class="active"
                  />
                  <!-- <el-image
                    :src="item.now"
                    :preview-src-list="srcList"
                    :initial-index="index + photo1.length"
                    fit="cover"
                  >
                  </el-image> -->
                  <span class="hint" v-if="hover2 === index">示例</span>
                  <div
                    class="owBottom"
                    :class="{ reviewed: reviewedItems.includes(item.type) }"
                  >
                    <!-- <el-checkbox-button :key="item.type" :label="item.type">
                      {{ item.name }}
                    </el-checkbox-button> -->
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="owner" v-if="photo3.length > 0">
              <span class="state-span">完成照&#12288;&#12288;</span>
              <div class="car-photo">
                <div
                  class="car-p1"
                  :class="{ reviewed2: reviewedItems.includes(item.type) }"
                  v-for="(item, index) in photo3"
                  :key="index"
                >
                  <img
                    :src="item.now + '?x-oss-process=style/A_X_S'"
                    @click="showPreview(photo3, index, 3, item.type, item.now)"
                    class="active"
                  />
                  <!-- <el-image
                    :src="item.now"
                    :preview-src-list="srcList"
                    :initial-index="index + photo1.length + photo2.length"
                    fit="cover"
                  >
                  </el-image> -->
                  <span class="hint" v-if="hover3 === index">示例</span>
                  <div
                    class="owBottom"
                    :class="{ reviewed: reviewedItems.includes(item.type) }"
                  >
                    <!-- <el-checkbox-button
                      id="btn"
                      :key="item.type"
                      :label="item.type"
                    >
                      {{ item.name }}
                    </el-checkbox-button> -->
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="owner" v-if="photoempty.length > 0">
              <span class="state-span">空驶照&#12288;&#12288;</span>
              <div class="car-photo">
                <div
                  class="car-p1"
                  :class="{ reviewed2: reviewedItems.includes(item.type) }"
                  v-for="(item, index) in photoempty"
                  :key="index"
                >
                  <img
                    :src="item.now + '?x-oss-process=style/A_X_S'"
                    @click="
                      showPreview(photoempty, index, 4, item.type, item.now)
                    "
                    class="active"
                  />
                  <!-- <el-image
                    :src="item.now"
                    :preview-src-list="srcList"
                    :initial-index="
                      index + photo1.length + photo2.length + photo3.length
                    "
                    fit="cover"
                    @click="clickimg(item.now)"
                  >
                  </el-image> -->
                  <span class="hint" v-if="hover4 === index">示例</span>
                  <div
                    class="owBottom"
                    :class="{ reviewed: reviewedItems.includes(item.type) }"
                  >
                    <!-- <el-checkbox-button
                      id="btn"
                      :key="item.type"
                      :label="item.type"
                    >
                      {{ item.name }}
                    </el-checkbox-button> -->
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>

        <div class="btn">
          <el-button
            class="set"
            :plain="true"
            style="background: orange;color:white;"
            @click="checkall"
            >照片全选</el-button
          >
          <el-button
            class="set"
            :plain="true"
            @click="pass"
            v-if="detail.isReview == 0"
            >初审通过</el-button
          >
          <el-button
            class="set1"
            :plain="true"
            @click="fail"
            v-if="detail.isReview == 0"
            >初审不过</el-button
          >
          <el-button
            class="set"
            :plain="true"
            @click="passfinal"
            v-if="detail.isReview == 1"
            >终审通过</el-button
          >
          <el-button
            class="set1"
            :plain="true"
            @click="failfinal"
            v-if="detail.isReview == 1"
            >终审不过</el-button
          >
        </div>
      </div>
    </div>

    <div class="fullscreen-overlay" v-if="showPreviewFlag">
      <div class="preview-images">
        <div class="yulanmain">
          <span class="biaoti">{{ phototit }}</span>
          <img
            :src="previewArray[currentIndex].now"
            class="preview-image"
            :style="{
              transform: `scale(${scale}) rotate(${rotationAngle}deg) translate(${translateX}px, ${translateY}px)`,
            }"
            @mousedown="startDrag"
            @wheel.stop="onWheel"
          />
        </div>
        <div class="yulanmain2">
          <div class="yulantop">
            <span class="biaoti2">示例照片</span>
            <img
              class="caozuoimg2"
              src="../assets/img/index/guanbi2.png"
              alt=""
              @click="hidePreview"
            />
            <div class="shilimgbox">
              <img
                :src="previewArray[currentIndex].img_src"
                class="preview-image2"
              />
            </div>
          </div>
          <div class="yulanbottom">
            <div
              class="shenhemain"
              v-for="(item, index) in reasonlist"
              :key="index"
              @click="xuan(item, index)"
              :class="{ selected: isSelected(item) }"
            >
              <span class="shenhetit">{{ item.t1 }}</span>
              <span class="shenhetxt">{{ item.t2 }}</span>
            </div>
          </div>

          <div class="shenhebtn" @click="shenbtn()">本张照片审核完成</div>
        </div>
      </div>
      <div class="button-container">
        <img
          class="caozuoimg"
          src="../assets/img/index/shangyibu.png"
          alt=""
          @click="prevImage"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/fangda.png"
          alt=""
          @click="increaseScale"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/suoxiao.png"
          alt=""
          @click="decreaseScale"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/huanyuan.png"
          alt=""
          @click="resetScale"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/zuozhuan.png"
          alt=""
          @click="zuozhaun"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/youzhuan.png"
          alt=""
          @click="youzhuan"
        />
        <img
          class="caozuoimg"
          src="../assets/img/index/xiayibu.png"
          alt=""
          @click="nextImage"
        />
        <a target="_blank" :href="yulanimg" download="image.jpg">
          <img class="caozuoimg" src="../assets/img/index/baocun.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import * as api1 from "../api/orderDetail";
import { ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import sports from "../views/new/sports.vue";
import { tr } from "element-plus/lib/locale/index.js";
const input1 = ref("");
const input2 = ref("");
const input3 = ref("");
const textarea1 = ref("");

export default {
  components: { sports },
  props: ["isaudio2", "rescueIdcheck"],
  data() {
    return {
      currentIndex: -1,
      showPreviewFlag: false,
      scale: 1,
      previewArray: [], //预览数组
      phototit: "",
      input1,
      input2,
      input3,
      textarea1,
      audios: this.isaudio2,
      id: "",
      detail: {},
      photo1: [],
      photo2: [],
      photo3: [],
      photoempty: [],
      photoall: [],
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList: [],
      checkList: [],
      shenshu: "",
      reasonlist: [],
      selectedItems: [], //选中的
      type: "",

      realyugu: "",
      realshiji: "",
      cha: "",
      reviewedItems: [], //改变按钮颜色
      currentType: "",
      clickCounter: 0,
      reason: [],
      hover: null,
      hover2: null,
      hover3: null,
      hover4: null,
      yulanimg: "",
      rotationAngle: 0,
      translateX: 0,
      translateY: 0,
      isDragging: false,
      startX: 0,
      startY: 0,
      initialTranslateX: 0,
      initialTranslateY: 0,
      resid: this.rescueIdcheck,
    };
  },
  created() {
    this.fresh(this.rescueIdcheck);
  },
  methods: {
    dingpart() {
      // console.log("状态状态:", this.rescueIdcheck);
      this.$emit("partbtn2", this.rescueIdcheck, this.$refs.checkbox);
    },
    xuan(item, index) {
      const selectedIndex = this.selectedItems.indexOf(item.t3);
      if (selectedIndex > -1) {
        // 已选中，取消选中
        this.selectedItems.splice(selectedIndex, 1);
      } else {
        // 未选中，添加选中
        this.selectedItems.push(item.t3);
      }

      console.log("选中没选中", this.selectedItems);
    },
    isSelected(item) {
      return this.selectedItems.includes(item.t3);
    },
    showPreview(array, index, num, type, img) {
      if (num == 1) {
        this.phototit = "现场照片";
      } else if (num == 2) {
        this.phototit = "作业照片";
      } else if (num == 3) {
        this.phototit = "完成照片";
      } else {
        this.phototit = "空驶照片";
      }
      console.log("预览照片的type", type);
      this.type = type;
      this.currentType = type;
      this.previewArray = array;
      this.currentIndex = index;
      this.showPreviewFlag = true;
      this.yulanimg = img;

      if (this.reason[type] != undefined) {
        this.selectedItems = this.reason[type];
      } else {
        this.selectedItems = [];
      }

      this.clickCounter += 1;
      this.checkList.push(this.clickCounter);
    },
    hidePreview() {
      this.translateX = 0;
      this.translateY = 0;
      this.showPreviewFlag = false;
      // this.selectedItems = [];
      // 关闭还原图片
      this.scale = 1;
      this.rotationAngle = 0;
    },
    prevImage() {
      this.rotationAngle = 0;

      if (this.selectedItems.length > 0) {
        this.reason[this.type] = this.selectedItems;
        if (!this.reviewedItems.includes(this.type)) {
          this.reviewedItems.push(this.type);
        }
      } else {
        let typeIndex = this.reviewedItems.indexOf(this.type);
        if (typeIndex > -1) {
          // 已选中，取消选中
          this.reviewedItems.splice(typeIndex, 1);
        }
      }

      this.currentIndex =
        (this.currentIndex - 1 + this.previewArray.length) %
        this.previewArray.length;
      this.scale = 1;
      this.yulanimg = this.previewArray[this.currentIndex].now;
      this.currentType = this.previewArray[this.currentIndex].type;
      this.type = this.currentType;
      if (this.reason[this.type] != undefined) {
        this.selectedItems = this.reason[this.type];
      } else {
        this.selectedItems = [];
      }
      console.log("点击点击", this.currentType);
    },
    nextImage() {
      this.rotationAngle = 0;
      console.log(this.selectedItems);
      if (this.selectedItems.length > 0) {
        this.reason[this.type] = this.selectedItems;
        if (!this.reviewedItems.includes(this.type)) {
          this.reviewedItems.push(this.type);
        }
      } else {
        let typeIndex = this.reviewedItems.indexOf(this.type);
        if (typeIndex > -1) {
          // 已选中，取消选中
          this.reviewedItems.splice(typeIndex, 1);
        }
      }

      // 更新 currentIndex
      this.currentIndex = (this.currentIndex + 1) % this.previewArray.length;
      this.scale = 1;

      // 更新 currentType 和 type
      this.yulanimg = this.previewArray[this.currentIndex].now;
      this.currentType = this.previewArray[this.currentIndex].type;
      this.type = this.currentType;

      if (this.reason[this.type] != undefined) {
        this.selectedItems = this.reason[this.type];
      } else {
        this.selectedItems = [];
      }

      console.log("点击点击", this.currentType);
    },
    decreaseScale() {
      if (this.scale > 0.186) {
        this.scale -= 0.1628;
      }
    },
    zuozhaun() {
      this.rotationAngle = (this.rotationAngle + 90) % 360;
    },
    youzhuan() {
      this.rotationAngle = (this.rotationAngle - 90) % 360;
    },
    resetScale() {
      this.translateX = 0;
      this.translateY = 0;
      this.rotationAngle = 0;
      this.scale = 1;
    },
    increaseScale() {
      if (this.scale < 7.531) {
        this.scale += 0.1628;
      }
    },

    close() {
      this.checkList = [];
      this.reviewedItems = [];
      this.reason = [];
      this.detail = {};
      this.id = "";
      // this.audios = false;
      this.$emit("guancheck");
    },
    checkChange(e) {
      console.log("----", e);
    },
    // clickimg(e) {
    // },

    dian() {
      console.log("点击了----");
    },
    // 审核
    shenbtn() {
      console.log("-=-=-=-选没选", this.selectedItems);
      if (this.selectedItems.length > 0) {
        this.reason[this.type] = this.selectedItems;
        if (!this.reviewedItems.includes(this.type)) {
          this.reviewedItems.push(this.type);
        }
        this.hidePreview();
        this.selectedItems = [];
      } else {
        let typeIndex = this.reviewedItems.indexOf(this.type);
        if (typeIndex > -1) {
          // 已选中，取消选中
          this.reviewedItems.splice(typeIndex, 1);
        }
        this.hidePreview();
        this.selectedItems = [];
      }
    },
    // 初审通过
    async pass() {
      if (this.reviewedItems.length > 0) {
        this.$message.warning("照片有问题,请确认是否有误");
      } else {
        ElMessageBox.confirm("是否确定初审通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api1.orderView(this.rescueIdcheck, 1, "", "");
            if (res.code == 1) {
              this.$message.success("成功");
              this.close();
              this.$emit("checksx", 1);
              this.$emit("checkedsx", 1);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.warning("取消");
          });
      }
    },
    // 初审不过
    async fail() {
      let content = "";
      if (this.reviewedItems.length == 0) {
        this.$message.warning("未找到有异常的照片，请确认");
        return;
      } else {
        content = this.reviewedItems.join(",");
      }

      let rs = {};
      if(this.reason.length > 0)
      {
        for (let i = 0; i < this.reviewedItems.length; i++) {
          let tt = this.reviewedItems[i];
          console.log(tt);
          rs[tt] = this.reason[tt].join(";");
        }
      }

      if (this.reviewedItems) {
        ElMessageBox.confirm("是否确定初审不通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api1.orderView(
              this.rescueIdcheck,
              2,
              content,
              JSON.stringify(rs)
            );

            if (res.code == 1) {
              this.$message.success("成功");
              this.close();
              this.$emit("checksx", 1);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.warning("取消");
          });
      } else {
        this.$message.warn("未找到有异常的照片，请确认");
      }
    },

    async passfinal() {
      if (this.reviewedItems.length > 0) {
        this.$message.warning("照片有问题,请确认是否有误");
      } else {
        ElMessageBox.confirm("是否确定终审通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api1.orderView(this.rescueIdcheck, 3, "", "");
            if (res.code == 1) {
              this.$message.success("成功");
              this.close();
              this.$emit("checksx", 1);
              this.$emit("checkedsx", 1);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.warning("取消");
          });
      }
    },
    async failfinal() {
      let content = "";
      if (this.reviewedItems.length == 0) {
        this.$message.warning("未找到有异常的照片，请确认");
        return;
      } else {
        content = this.reviewedItems.join(",");
      }

      let rs = {};
      if(this.reason.length > 0)
      {
        for (let i = 0; i < this.reviewedItems.length; i++) {
          let tt = this.reviewedItems[i];
          console.log(tt);
          rs[tt] = this.reason[tt].join(";");
        }
      }


      if (this.reviewedItems) {
        ElMessageBox.confirm("是否确定终审不通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api1.orderView(
              this.rescueIdcheck,
              4,
              content,
              JSON.stringify(rs)
            );

            if (res.code == 1) {
              this.$message.success("成功");
              this.close();
              this.$emit("checksx", 1);
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.warning("取消");
          });
      } else {
        this.$message.warn("未找到有异常的照片，请确认");
      }
    },
    async fresh() {
      // let newVal = this.id;
      let res_orderDetail = await api1.OrderDetail(this.rescueIdcheck);
      this.detail = res_orderDetail.data;
      this.shenshu = this.detail.appealcontent;
      this.realyugu = res_orderDetail.data.预估拖车距离;
      this.realshiji = res_orderDetail.data.拖车距离;
      const numericA = parseFloat(this.realyugu.replace(/[^\d.-]/g, ""));
      const numericB = parseFloat(this.realshiji.replace(/[^\d.-]/g, ""));

      this.cha = Math.abs(numericA - numericB);
      console.log("看看相差多少", this.cha);
      console.log(this.detail);
      let res_orderphotos = await api1.OrderPhotoSh(this.rescueIdcheck);
      this.photo1 = res_orderphotos.data.photo1;
      this.photo2 = res_orderphotos.data.photo2;
      this.photo3 = res_orderphotos.data.photo3;
      this.photoempty = res_orderphotos.data.photoempty;
      this.photoall = res_orderphotos.data.photoall;
      this.reasonlist = res_orderphotos.data.reasonlist;
      console.log("-=-=-=-=", this.photo1);
      console.log("新增审核详情", this.reasonlist);

      let newarr = Object.values(this.photoall);
      console.log(newarr);
      this.srcList = newarr.map((item, index, arr) => {
        return item.now;
      });
    },

    xian(index) {
      this.hover = index;
    },
    yin() {
      this.hover = null;
    },
    xian2(index) {
      this.hover2 = index;
    },
    yin2() {
      this.hover2 = null;
    },
    xian3(index) {
      this.hover3 = index;
    },
    yin3() {
      this.hover3 = null;
    },
    xian4(index) {
      this.hover4 = index;
    },
    yin4() {
      this.hover4 = null;
    },

    // 点击状态
    typebtn() {
      console.log("状态状态:", this.$refs.checkbox);
      this.$emit("partbtn", this.id, this.$refs.checkbox);
      // this.$refs.checkbox.classList.add("cengji");
    },

    async appeal() {
      if (this.shenshu == "") {
        this.$message.warning("请输入你要申诉的内容");
        return;
      }

      let res = await api1.rescueAppeal(this.rescueIdcheck, this.shenshu);

      if (res.code == 1) {
        this.$message.success("成功");
        this.fresh();
      } else {
        this.$message.warning(res.message);
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.initialTranslateX = this.translateX;
      this.initialTranslateY = this.translateY;
      window.addEventListener("mousemove", this.onDrag);
      window.addEventListener("mouseup", this.endDrag);
      event.preventDefault();
    },

    onDrag(event) {
      if (this.isDragging) {
        const dx = event.clientX - this.startX;
        const dy = event.clientY - this.startY;
        this.translateX = this.initialTranslateX + dx;
        this.translateY = this.initialTranslateY + dy;
      }
    },
    endDrag() {
      if (this.isDragging) {
        this.isDragging = false;
        window.removeEventListener("mousemove", this.onDrag);
        window.removeEventListener("mouseup", this.endDrag);
      }
    },
    onWheel(event) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      if (delta < 0) {
        // 滚轮放大图片
        if (this.scale < 7.454) {
          this.scale += 0.1628;
        }
      } else if (delta > 0) {
        // 滚轮缩小图片
        if (this.scale > 0.194) {
          this.scale -= 0.1628;
        }
      }
    },
    call(tel, str) {
      let ue = this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber: tel.toString(),
        loginType: mode,
        extras: str,
      });
    },
    checkall()
    {
      let that = this
      this.photoall.forEach(function(eve){
         if(eve.type != 10)
         {
          that.reviewedItems.push(eve.type);
         }
      });
    }
  },
  computed:{
    getEmic() {
      return this.$store.state.emic;
    },
  },

  watch: {
    isaudio2(newval) {
      this.audios = newval;
      if (this.id != "") {
        this.fresh();
      }
    },
    // rescueIdcheck(newVal) {
    //   if (newVal != "") {
    //     console.log("订单号变更", newVal);
    //     this.checkList = [];
    //     this.id = newVal;
    //     this.fresh();
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.car-p1 /deep/.el-checkbox-button__inner {
  width: 120px;
  font-size: 13px;
  line-height: 20px;
  padding: 0;
  overflow: hidden;
  margin-top: -4px;
}
.car-p1 img {
  width: 120px;
  height: 100px;
  cursor: pointer;
  display: block;
}
.car-p1 img.active {
  border: 0px solid #000;
}
.box {
  width: 700px;
  // height: auto;
  margin-top: 0px;
  float: right;
  opacity: 1;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 60;
}
.cha {
  float: left;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.box-main {
  width: 720px;
  height: 920px;
  background: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.4);
  opacity: 1;
  border-radius: 0px;
  // position: absolute;
  // right: 0;
  position: relative;
  overflow-y: scroll;
  padding-left: 30px;
  box-sizing: border-box;
}
.box-main::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.main-nav {
  width: 700px;
  height: 80px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
}
h1 {
  line-height: 80px;
  padding-top: 20px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.state {
  width: 500px;
  height: 40px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  //   background-color: skyblue;
}
.state-span {
  float: left;
  width: 100px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-bottom: 20px;
}
.state-p {
  display: inline-block;
  color: #2c68ff;
  margin: 0;
  padding: 0 12px;
  line-height: 40px;
  border: 1px solid #dadfe6;
  cursor: pointer;
}
.cartit {
  display: flex;
  height: 40px;
  align-items: center;
}
.owner {
  display: flex;
  //   width: 600px;
  font-size: 16px;
  margin-top: 22px;
  line-height: 21px;
  // margin-left: 34px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #5983df;
  opacity: 1;
  float: left;
  clear: both;
}

.car-p1 {
  float: left;
  margin-right: 30px;
  // height: 125px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #dfdce6;
}
.active {
  width: 120px !important;
  height: 100px !important;
}
// .car-photo {
//     width: 400px;
// }
.car-img {
  width: 120px;
  height: 100px;
  margin-top: 12px;
}
.owBottom {
  width: 120px;
  font-size: 12px;
  text-align: center;

  color: #606266;
  border-top: 1px solid #dcdfe6;
}
.hint {
  font-size: 14px;
  background-color: #22c680;
  color: #fff;
  position: absolute;
  padding: 1px 2px;
  box-sizing: border-box;
}
.choice {
  vertical-align: middle;
  margin-left: 15px;
  margin-right: 5px;
}
.car-span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}
.abnormal {
  display: flex;
  width: 510px;
  // height: 40px;
  line-height: 35px;
  // margin-left: 34px;
  margin-top: 20px;
  //   background-color: gold;
  position: relative;
  float: left;
}
.yichang {
  width: 120px;
  font-size: 14px;
  line-height: 35px;
  color: #2a3346;
}
label {
  width: 70px;
  height: 19px;
  font-size: 14px;
  // margin-right: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
  float: left;
}
.appeal {
  font-style: normal;
  width: 70px;
  height: 21px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  margin-left: 20px;
  color: #2c68ff;
  opacity: 1;
  float: left;
  clear: both;
}
.warn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 80px;
  float: left;
}

.btn {
  float: left;
  // width: 280px;
  height: 32px;
  opacity: 1;
  margin-left: 34px;
  margin-top: 44px;
  // background-color: mediumspringgreen;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 10px;
}
.set1 {
  width: 80px;
  height: 32px;
  background: #ff2c3e;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
  margin-right: 20px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  // margin-left: 20px;
  border-radius: 2px;
  vertical-align: middle;
}

.el-checkbox {
  display: inline-block;
  text-align: center;
}
.owBottom /deep/ .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 0;
}

.car-p1 /deep/ .el-image {
  width: 120px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-bottom: 0;
  box-sizing: border-box;
}
.cengji {
  z-index: 30;
}
.tiao,
.tiao1 {
  width: 3px;
  height: 15px;
  float: left;
  background: #2c68ff;
  opacity: 1;
  border-radius: 0px;
}
.tiao {
  position: absolute;
  top: 93px;
  left: 20px;
}
.tiao1 {
  position: absolute;
  top: 4px;
  left: 0;
  margin-right: 10px;
}
.ordernum {
  height: 21px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 21px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2a3346;
  opacity: 1;
}
.take {
  display: inline-block;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin-left: 6px;
  box-sizing: border-box;
  background: #e8f3fe;
  opacity: 1;
  border-radius: 2px;
  color: #2c68ff;
  vertical-align: top;
  padding: 0 5px;
}
.order-main {
  height: auto;
  opacity: 1;
  background-color: #fff;
}
.hezuo {
  display: flex;
}
.p1 {
  margin-bottom: 10px;
  width: 400px;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
}
.title-main {
  width: 250px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  opacity: 1;
  word-break: break-all;
}
.xiangmu {
  display: flex;
}
.yanse {
  font-size: 14px;
}
.title {
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2a3346;
  opacity: 1;
  margin-right: 30px;
}
i {
  font-style: normal;
}
.diBox {
  display: flex;
  width: 730px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.remTit {
  margin-right: 30px;
}
.diTxt2 {
  color: #2c68ff;
  width: 500px;
}
.diTxt {
  width: 500px;
}
.owner {
  position: relative;
  margin-top: 20px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  width: 637px;
  box-sizing: border-box;
}
.feiBox {
  //   margin-left: 30px;
  margin-top: 20px;
}
.tishi {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.feiyong {
  display: flex;
  flex-direction: column;
}
.feiyongtit {
  font-weight: bold;
  color: #2a3346;
  font-size: 16px;
}
.piancha {
  background-color: #fe2736;
  padding: 1px 11px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
}
.nor1,
.nor2,
.nor3,
.nor4 {
  width: 637px;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.nor2 {
  border-top: 0;
}
.nor3 {
  border-top: 0;
}
.nor4 {
  border-top: 0;
}
.norm,
.roam,
.tuo1,
.tuo2 {
  width: 100px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  background-color: #f8fafc;
  color: #2a3346;
  font-size: 16px;
  border-left: 1px solid #dadfe6;
  border-right: 1px solid #dadfe6;
  box-sizing: border-box;
}
.norm {
  border-right: 1px solid #dadfe6;
}
.roam {
  border-top: 0;
}
.normMain,
.tuo,
.roamMain,
.tuo1Main,
.yuMain,
.tuo2Main {
  height: 50px;
  line-height: 50px;
  vertical-align: top;
  padding-left: 10px;
  display: inline-block;
  display: inline-block;
  font-size: 14px;
  box-sizing: border-box;
  border-left: 0;
}
.tuo {
  color: #2c68ff;
}
.normMain {
  width: 250px;
}
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.preview-images {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}
.yulanmain,
.yulantop,
.yulanmain2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.yulanmain {
  width: 1000px;
  align-items: flex-start;
  background: rgba(147, 147, 147, 0.1);
}
.yulanmain,
.yulanmain2 {
  height: 823px;
}
.yulanmain2,
.yulantop {
  align-items: flex-end;
  position: relative;
}
.preview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 5;
}
.shenhetit {
  width: 150px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.shenhetxt {
  width: 500px;
  font-size: 12px;
}
.yulanbottom {
  width: 700px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 20px;
  // max-height: 300px;
  // overflow-y: scroll;
}
.shenhemain {
  display: flex;
  height: 27px;
  align-items: center;
  cursor: pointer;
  transition: border-color 0.3s;
}
.shenhemain:nth-child(odd) {
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
}

.shenhemain:nth-child(even) {
  background-color: #e9e9e9;
  border: 1px solid #e9e9e9;
}
.shenhemain:hover {
  background-color: #e9e9e9;
}
.biaoti,
.biaoti2 {
  width: 89px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: -40px;
}
.biaoti {
  background-color: #2c68ff;
}
.biaoti2 {
  background-color: #22c680;
}

.shilimgbox {
  width: 700px;
  height: 366px;
  background-color: #fff;
}
.preview-image2 {
  width: 100%;
  height: 366px;
}
.button-container {
  position: fixed;
  bottom: 30px;
  margin-top: 20px;
  background-color: #6a6b6f;
  padding: 10px 20px;
  border-radius: 50px;
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}
.shenhebtn {
  width: 225px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #2c68ff;
  font-size: 14px;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
.selected {
  border: 1px solid red !important;
}
.reviewed {
  background: red;
  color: #fff;
  border-top: 1px solid red;
}
.reviewed2 {
  border: 1px solid red;
}
.caozuoimg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.caozuoimg2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  right: -45px;
}
.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
</style>
