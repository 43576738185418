<template>
  <div class="searchBox" v-show="isWei">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="searchTit">位置选择</p>

      <div class="xuanze">
        <label class="xuanzeTit" for="">选择位置</label>
        <input
          class="xuanzeInput"
          type="text"
          id="addr"
          autocomplete="off"
          v-model="addr"
          placeholder="请在地图上选择位置"
        />
        <input type="hidden" id="lnglat"/>
        <button class="quer1" @click="search">搜索</button>
        <button class="quer" @click="queding">确认</button>
      </div>

      <div class="searchmapBox">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import * as api from "../api/index";
export default {
  props: {
    isWei: {
      default: false,
      type: Boolean,
    },
    isaddr: {
      default: 0,
      type: Number,
    },
    foundaddr: {
      default: "",
      type: String,
    },
    foundlnglat: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      addr: this.foundaddr,
      lnglat: this.foundlnglat,
      searchlng: "",
      searchlat: "",
      List: [],
      map: "",
      cc:0,
    };
  },
  created() {
    window.getaddress = this.getaddress;
  },
  // unmounted() {
  //   this.map.destory();
  // },
  methods: {
    close() {
      Object.assign(this.$data, this.$options.data());
      this.$emit("hide"); // 只关闭地图组件
    },
    searchMap() {
      console.log("地图方法接收值", this.lnglat);
      if (this.lnglat != "" && this.lnglat != undefined) {
        var a = this.lnglat.split(",");
        var map = new AMap.Map("map", {
          center: [a[0], a[1]],
          resizeEnable: true,
          zoom: 13,
        });
        console.log("进来了123", this.addr);
        console.log("进来了1234", this.lnglat);
        document.getElementById("addr").value = this.addr;
        document.getElementById("lnglat").value = this.lnglat;
        CreateMarker([a[0], a[1]]);
      } else {
        var map = new AMap.Map("map", {
          center: [121.621989, 29.853658],
          resizeEnable: true,
          zoom: 10,
        });
        document.getElementById("addr").value = "";
        document.getElementById("lnglat").value = "";
      }

      map.on("click", OnMapClick);

      map.setFitView();

      function OnMapClick(e) {
        map.clearMap();

        setLocation(e.lnglat, true);

        getaddress(e.lnglat.getLng(), e.lnglat.getLat());
      }

      function setLocation(argPoint, bCenter) {
        if (argPoint) {
          // 将标注添加到地图中
          CreateMarker(argPoint);

          // 移动到视野中心
          if (bCenter) map.setCenter(argPoint);
        }
      }
      function CreateMarker(point) {
        var opt = {
          map: map,
          position: point,
        };
        return new AMap.Marker(opt);
      }
    },
    async search() {
      let res = await api.workMap(this.addr);
      this.lnglat = res.data[0].location;
      this.searchMap();
      this.getcity(this.lnglat);
    },
    async getaddress(lng, lat) {
      let res = await api.getaddress(lng + "," + lat);
      document.getElementById("addr").value = res.data.addr;
      document.getElementById("lnglat").value = lng + "," + lat;
      // console.log("选中地址",res);
      this.addr = res.data.addr;
      this.lnglat = lng+','+lat;
      this.cc = res.data.citycode;
      
    },
    async getcity(lnglat) {
      let res = await api.getaddress(lnglat);
      this.cc = res.data.citycode;
      // console.log("选中地址",res);
    },
    queding() {
      if (this.isaddr == 1) {
        this.$emit("addr1", this.addr);
        this.$emit("addr1lnglat", this.lnglat);
        this.$emit("addcitycode", this.cc);
        this.close();
      } else {
        this.$emit("addr2", this.addr);
        this.$emit("addr2lnglat", this.lnglat);
        this.$emit("add2citycode", this.cc);
        this.close();
      }
    },
  },
  watch: {
    isWei(newval)
    {
      this.searchMap();
    },
    foundaddr(newval) {
      console.log("进入地图1", newval);
      this.addr = newval;
    },
    foundlnglat(newval) {
      console.log("进入地图2", newval);
      this.lnglat = newval;
      this.searchMap();
      this.getcity(this.lnglat);
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 640px;
  background-color: skyblue;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
}
.searchMain {
  width: 640px;
  height: 920px;
  background-color: #fff;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 70px;
  left: -40px;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  padding-left: 30px;
  padding-top: 40px;
  font-weight: bold;
  box-sizing: border-box;
}
.xuanze {
  margin-top: 10px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}

.xuanzeTit {
  font-size: 14px;
  color: #2a3346;
  margin-right: 8px;
}
.xuanzeInput {
  width: 350px;
  height: 32px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  padding-left: 12px;
}
.quer {
  height: 32px;
  padding: 0 16px;
  border: 0;
  background-color: #2c68ff;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.quer1 {
  height: 32px;
  padding: 0 16px;
  border: 0;
  background-color: #4fff2c;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}
.searchmapBox {
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  margin-top: 10px;
}
#map {
  width: 600px;
  height: 100%;
  border: 1px solid #dcdfe6;
}
</style>